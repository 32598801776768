import * as Types from "../types/CompanyTypes";

// GET LIST
export const getCompaniesRequest = (params) => ({
  type: Types.GET_COMPANIES_REQUEST,
  params,
});
export const getCompaniesSuccess = (result) => ({
  type: Types.GET_COMPANIES_SUCCESS,
  result,
});

// GET COMPANY ITEM
export const getCompanyRequest = (params) => ({
  type: Types.GET_COMPANY_REQUEST,
  params,
});
export const getCompanySuccess = (result) => ({
  type: Types.GET_COMPANY_SUCCESS,
  result,
});

// CREATE
export const createCompanyRequest = (payload) => ({
  type: Types.CREATE_COMPANY_REQUEST,
  payload,
});
export const createCompanySuccess = (result) => ({
  type: Types.CREATE_COMPANY_SUCCESS,
  result,
});

// UPDATE
export const updateCompanyRequest = (payload) => ({
  type: Types.UPDATE_COMPANY_REQUEST,
  payload,
});
export const updateCompanySuccess = (result) => ({
  type: Types.UPDATE_COMPANY_SUCCESS,
  result,
});

// DELETE
export const deleteCompanyRequest = (params) => ({
  type: Types.DELETE_COMPANY_REQUEST,
  params,
});
export const deleteCompanySuccess = (result) => ({
  type: Types.DELETE_COMPANY_SUCCESS,
  result,
});

export const changeStatusCompanyRequest = (payload) => ({
  type: Types.CHANGE_STATUS_COMPANY_REQUEST,
  payload,
});
export const changeStatusCompanySuccess = (result) => ({
  type: Types.CHANGE_STATUS_COMPANY_SUCCESS,
  result,
});

// UPLOAD
export const uploadCompanyLogoRequest = (payload) => ({
  type: Types.UPLOAD_COMPANY_LOGO_REQUEST,
  payload,
});
export const uploadCompanyLogoSuccess = (result) => ({
  type: Types.UPLOAD_COMPANY_LOGO_SUCCESS,
  result,
});
