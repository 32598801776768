import * as Types from "../types/ApprovalType";

const initialState = {
  approvals: [],
  approval_histories: [],
};

const approvalReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_APPROVALS_SUCCESS: {
      return {
        ...state,
        approvals: action?.result,
      };
    }
    case Types.GET_APPROVAL_HISTORIES_SUCCESS: {
      state.approval_histories = action?.result
      return state
    }
    case Types.GRANT_APPROVAL_SUCCESS: {
      const approvals = state?.approvals.filter(
        (request) => request?.id !== action?.result?.timeoff_request_id
      );

      if (state?.approval_histories?.length !== 0) {
        var updateApprovalHistories = [
          action?.result.timeoff_histories, ...state.approval_histories
        ]
      }

      return {
        ...state,
        approvals: approvals,
        approval_histories: updateApprovalHistories
      };
    }
    default:
      return state;
  }
};

export default approvalReducer;
