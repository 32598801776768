import { notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import * as Types from "../types/CompanyTypes";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/companyAction";
import * as FunctionService from "../services/companyService";
import * as AuthAction from "../actions/authAction";

function* getCompanies() {
  yield takeLatest(
    Types.GET_COMPANIES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getCompanies, action?.params);
      yield put(FunctionAction.getCompaniesSuccess(result));
    }, errorHandle(Types.GET_COMPANIES_FAILURE))
  );
}

function* getCompany() {
  yield takeLatest(
    Types.GET_COMPANY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getCompany, action?.payload);
      yield put(FunctionAction.getCompanySuccess(result));
    }, errorHandle(Types.GET_COMPANY_FAILURE))
  );
}

function* createCompany() {
  yield takeLatest(
    Types.CREATE_COMPANY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.createCompany, action?.payload);
      yield put(FunctionAction.createCompanySuccess(result));
      notification.success({ message: "Create Successfully" });
    }, errorHandle(Types.CREATE_COMPANY_FAILURE))
  );
}

function* updateCompany() {
  yield takeLatest(
    Types.UPDATE_COMPANY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.updateCompany, action?.payload);
      yield put(FunctionAction.updateCompanySuccess(result));
      notification.success({ message: "Update Successfully" });
    }, errorHandle(Types.UPDATE_COMPANY_FAILURE))
  );
}

function* changeCompanyStatus() {
  yield takeLatest(
    Types.CHANGE_STATUS_COMPANY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.changeCompanyStatus,
        action?.payload
      );
      yield put(FunctionAction.changeStatusCompanySuccess(result));
      notification.success({ message: "Update Successfully" });
    }, errorHandle(Types.CHANGE_STATUS_COMPANY_FAILURE))
  );
}

function* deleteCompany() {
  yield takeLatest(
    Types.DELETE_COMPANY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.deleteCompany, action?.params);
      yield put(FunctionAction.deleteCompanySuccess(action?.params));
      notification.success({ message: "Delete Successfully" });
    }, errorHandle(Types.DELETE_COMPANY_FAILURE))
  );
}

function* uploadCompanyLogo() {
  yield takeLatest(
    Types.UPLOAD_COMPANY_LOGO_REQUEST,
    Func.sagaWrapper(function* (action) {
      const { data, onSuccess, onProgress } = action?.payload;
      const result = yield call(FunctionService.uploadCompanyLogo, {
        data,
        onProgress,
      });
      yield put(
        FunctionAction.uploadCompanyLogoSuccess({ ...result, onSuccess })
      );
      notification.success({ message: "Upload Successfully" });
      yield put(AuthAction.checkUserRequest());
    }, errorHandle(Types.UPLOAD_COMPANY_LOGO_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* companySaga() {
  yield all([
    fork(getCompanies),
    fork(getCompany),
    fork(createCompany),
    fork(updateCompany),
    fork(deleteCompany),
    fork(uploadCompanyLogo),
    fork(changeCompanyStatus),
  ]);
}
