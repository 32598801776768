const Define = {};
export default Define;

export const PATHS = {
  ROOT: { path: "/" },
  // AUTHENTICATION_NOT_FOUND: {path: `${Config.API_BASE_URL}/auth/login`},
  LOGIN: { path: "/login" },
  LOGOUT: { path: "/logout" },
  PROFILE: { path: "/profile" },
  REFRESH: { path: "/refresh" },
  PAGE_403: { path: "/403" },
};

export const CONFIG_LOCAL_STORAGE = {
  PREFIX: "hn",
  COMMON_ACCESS_TOKEN: "hn_utk",
  ACCESS_TOKEN: "utk",
  REFRESH_TOKEN: "rft",
  ACCOUNT_ID: "aid",
  EXPIRES: "expires",
  PROFILE: "pf",
  MENU_COLLAPSED: "menu_collapsed",
  CONTENT_LANGUAGE: "content_language",
  TOKEN_V1: "connect.sid",
};

export const DATE_TIME_FORMAT = {
  MINIMUM_YEAR: 1970,
  SHORT_DATE: "DD/MM/YYYY",
  SHORT_DATE_EXPORT: "YYYY-MM-DD",
  DATE_TIME: "DD/MM/YYYY HH:mm",
  EN_DATE_TIME: "YYYY-MM-DD HH:mm:ss",
  DAY_WEEK_DATETIME: "dddd, DD/MM/YYYY HH:mm",
  TIME_AND_DATE: "HH:mm DD/MM/YYYY",
  SHORT_TIME: "HH:mm",
  FULL_TIME: "HH:mm:ss",
  SHORT_MONTH: "MM/YYYY",
};

export const PAGINATION = {
  page_index: 1,
  page_size: 10,
  total: 0,
  total_page: 1,
};

export const REFRESH_TOKEN_DELAY_TIMEOUT = 5 * 60 * 1000; // 5 minutes before the access token was expired
