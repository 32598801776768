import { notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import CustomerRequest from "../mapping/Request/CustomerRequest";
import * as Types from "../types/CustomerTypes";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/customerAction";
import * as FunctionService from "../services/customerService";

function* getCustomers() {
  yield takeLatest(
    Types.GET_CUSTOMERS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getCustomers, action?.params);
      yield put(FunctionAction.getCustomersSuccess(result));
    }, errorHandle(Types.GET_CUSTOMERS_FAILURE))
  );
}

function* createCustomer() {
  yield takeLatest(
    Types.CREATE_CUSTOMER_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = new CustomerRequest(action?.payload).exportCreate();
      const result = yield call(FunctionService.createCustomer, data);
      yield put(FunctionAction.createCustomerSuccess(result));
      notification.success({ message: "Create Successfully" });
    }, errorHandle(Types.CREATE_CUSTOMER_FAILURE))
  );
}

function* updateCustomer() {
  yield takeLatest(
    Types.UPDATE_CUSTOMER_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = new CustomerRequest(action?.payload).exportUpdate();
      const result = yield call(FunctionService.updateCustomer, data);
      yield put(FunctionAction.updateCustomerSuccess(result));
      notification.success({ message: "Update Successfully" });
    }, errorHandle(Types.UPDATE_CUSTOMER_FAILURE))
  );
}

function* deleteCustomer() {
  yield takeLatest(
    Types.DELETE_CUSTOMER_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteCustomer, action?.params);
      yield put(FunctionAction.deleteCustomerSuccess(action?.params));
      notification.success({ message: "Delete Successfully" });
    }, errorHandle(Types.DELETE_CUSTOMER_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* customerSaga() {
  yield all([
    fork(getCustomers),
    fork(createCustomer),
    fork(updateCustomer),
    fork(deleteCustomer),
  ]);
}
