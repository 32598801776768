export const SIGNIN = "SIGNIN";
export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAILURE = "SIGNOUT_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const CHECK_USER = "CHECK_USER";
export const CHECK_USER_REQUEST = "CHECK_USER_REQUEST";
export const CHECK_USER_SUCCESS = "CHECK_USER_SUCCESS";
export const CHECK_USER_FAILURE = "CHECK_USER_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
