import { notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import * as Types from "../types/PayRollType";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/payRollAction";
import * as FunctionService from "../services/payRollService";

function* getEmployeesOnPayRoll() {
  yield takeLatest(
    Types.GET_EMPLOYEES_ON_PAY_ROLL_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getEmployeesOnPayRoll);
      yield put(FunctionAction.getEmployeesOnPayRollSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEES_ON_PAY_ROLL_FAILURE))
  );
}

function* getDraftPayRuns() {
  yield takeLatest(
    Types.GET_DRAFT_PAY_RUNS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getDraftPayRuns);
      yield put(FunctionAction.getDraftPayRunsSuccess(result));
    }, errorHandle(Types.GET_DRAFT_PAY_RUNS_FAILURE))
  );
}

function* getApprovedPayRuns() {
  yield takeLatest(
    Types.GET_APPROVED_PAY_RUNS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getApprovedPayRuns);
      yield put(FunctionAction.getApprovedPayRunsSuccess(result));
    }, errorHandle(Types.GET_APPROVED_PAY_RUNS_FAILURE))
  );
}

function* getPaidPayRuns() {
  yield takeLatest(
    Types.GET_PAID_PAY_RUNS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getPaidPayRuns);
      yield put(FunctionAction.getPaidPayRunsSuccess(result));
    }, errorHandle(Types.GET_PAID_PAY_RUNS_FAILURE))
  );
}

function* approveDraftPayRun() {
  yield takeLatest(
    Types.APPROVE_DRAFT_PAY_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.approveDraftPayRun, action?.payload);
      yield put(FunctionAction.approveDraftPayRunSuccess(action?.payload));
      notification.success({ message: "Approve Pay Run Successfuly" });
    }, errorHandle(Types.APPROVE_DRAFT_PAY_RUN_FAILURE))
  );
}

function* revertApprovedPayRun() {
  yield takeLatest(
    Types.REVERT_APPROVED_PAY_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.revertApprovedPayRun, action?.payload);
      yield put(FunctionAction.revertApprovedPayRunSuccess(action?.payload));
      notification.success({ message: "Revert Pay Run To Draft Successfuly" });
    }, errorHandle(Types.REVERT_APPROVED_PAY_RUN_FAILURE))
  );
}

function* addEmployeeOnPayRoll() {
  yield takeLatest(
    Types.ADD_EMPLOYEE_ON_PAY_ROLL_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.addEmployeeOnPayRoll, action?.payload);
      yield put(FunctionAction.addEmployeeOnPayRollSuccess(action?.payload));
      notification.success({ message: "Employee Included In Pay Roll" });
    }, errorHandle(Types.ADD_EMPLOYEE_ON_PAY_ROLL_FAILURE))
  );
}

function* removeEmployeeOnPayRoll() {
  yield takeLatest(
    Types.REMOVE_EMPLOYEE_ON_PAY_ROLL_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.removeEmployeeOnPayRoll, action?.payload);
      yield put(FunctionAction.removeEmployeeOnPayRollSuccess(action?.payload));
      notification.success({ message: "Employee Excluded From Pay Roll" });
    }, errorHandle(Types.REMOVE_EMPLOYEE_ON_PAY_ROLL_FAILURE))
  );
}

function* updatePayRun() {
  yield takeLatest(
    Types.UPDATE_PAY_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.updatePayRun, action?.payload);
      yield put(FunctionAction.updatePayRunSuccess(result));
      notification.success({ message: "Update Pay Run Successfully" });
    }, errorHandle(Types.UPDATE_PAY_RUN_FAILURE))
  );
}

function* deletePayRun() {
  yield takeLatest(
    Types.DELETE_PAY_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deletePayRun, action?.payload);
      yield put(FunctionAction.deletePayRunSuccess(action?.payload));
      notification.success({ message: "Delete Pay Run Successfully" });
    }, errorHandle(Types.DELETE_PAY_RUN_FAILURE))
  );
}

function* addEmployeesInPayRun() {
  yield takeLatest(
    Types.ADD_EMPLOYEES_IN_PAY_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.addEmployeeInPayRun, action?.payload);
      yield put(FunctionAction.addEmployeeInPayRunSuccess(action?.payload));
      notification.success({ message: "Employees Added Successfully" });
    }, errorHandle(Types.ADD_EMPLOYEES_IN_PAY_RUN_FAILURE))
  );
}

function* updateEmployeeInPayRun() {
  yield takeLatest(
    Types.UPDATE_EMPLOYEE_IN_PAY_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateEmployeeInPayRun,
        action?.payload
      );
      yield put(
        FunctionAction.updateEmployeeInPayRunSuccess({
          ...result,
          payRunId: action?.payload?.payRunId,
        })
      );
      notification.success({ message: "Employee Updated Successfully" });
      // yield put(FunctionAction.getDraftPayRunsRequest());
    }, errorHandle(Types.UPDATE_EMPLOYEE_IN_PAY_RUN_FAILURE))
  );
}

function* addOffCycleRun() {
  yield takeLatest(
    Types.ADD_OFF_CYCLE_RUN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.addOffCycleRun,
        action?.payload
      );
      yield put(FunctionAction.addOffCycleRunSuccess(result));
      notification.success({ message: "Employee Updated Successfully" });
    }, errorHandle(Types.ADD_OFF_CYCLE_RUN_FAILURE))
  );
}

function* getEarnings() {
  yield takeLatest(
    Types.GET_EARNINGS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getEarnings, action?.payload);
      yield put(FunctionAction.getEarningsSuccess(result));
    }, errorHandle(Types.GET_EARNINGS_FAILURE))
  );
}

function* getDeductions() {
  yield takeLatest(
    Types.GET_DEDUCTIONS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getDeductions, action?.payload);
      yield put(FunctionAction.getDeductionsSuccess(result));
    }, errorHandle(Types.GET_DEDUCTIONS_FAILURE))
  );
}

function* createEarningOrDeduction() {
  yield takeLatest(
    Types.CREATE_EARNING_OR_DEDUCTION_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createEarningOrDeduction,
        action?.payload
      );
      yield put(FunctionAction.createEarningOrDeductionSuccess(result));
      notification?.success({
        message: "Create Successfully",
      });
    }, errorHandle(Types.CREATE_EARNING_OR_DEDUCTION_FAILURE))
  );
}

function* updateEarningOrDeduction() {
  yield takeLatest(
    Types.UPDATE_EARNING_OR_DEDUCTION_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateEarningOrDeduction,
        action?.payload
      );
      yield put(FunctionAction.updateEarningOrDeductionSuccess(result));
    }, errorHandle(Types.UPDATE_EARNING_OR_DEDUCTION_FAILURE))
  );
}

function* deleteEarningOrDeduction() {
  yield takeLatest(
    Types.DELETE_EARNING_OR_DEDUCTION_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteEarningOrDeduction, action?.payload);
      yield put(
        FunctionAction.deleteEarningOrDeductionSuccess(action?.payload)
      );
    }, errorHandle(Types.DELETE_EARNING_OR_DEDUCTION_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* payRollSaga() {
  yield all([
    fork(getEmployeesOnPayRoll),
    fork(getDraftPayRuns),
    fork(getApprovedPayRuns),
    fork(getPaidPayRuns),
    fork(approveDraftPayRun),
    fork(revertApprovedPayRun),
    fork(addEmployeeOnPayRoll),
    fork(removeEmployeeOnPayRoll),
    fork(updatePayRun),
    fork(deletePayRun),
    fork(addEmployeesInPayRun),
    fork(updateEmployeeInPayRun),
    fork(addOffCycleRun),
    fork(getEarnings),
    fork(getDeductions),
    fork(createEarningOrDeduction),
    fork(updateEarningOrDeduction),
    fork(deleteEarningOrDeduction),
  ]);
}
