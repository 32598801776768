export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_DEPARTMENTS_REQUEST = "GET_DEPARTMENTS_REQUEST";
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_DEPARTMENTS_FAILURE = "GET_DEPARTMENTS_FAILURE";

export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_REQUEST = "CREATE_DEPARTMENT_REQUEST";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";

export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";
