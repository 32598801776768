import * as Types from "../types/CustomerTypes";

// GET LIST
export const getCustomersRequest = (params) => ({
  type: Types.GET_CUSTOMERS_REQUEST,
  params,
});
export const getCustomersSuccess = (result) => ({
  type: Types.GET_CUSTOMERS_SUCCESS,
  result,
});

// CREATE
export const createCustomerRequest = (payload) => ({
  type: Types.CREATE_CUSTOMER_REQUEST,
  payload,
});
export const createCustomerSuccess = (result) => ({
  type: Types.CREATE_CUSTOMER_SUCCESS,
  result,
});

// UPDATE
export const updateCustomerRequest = (payload) => ({
  type: Types.UPDATE_CUSTOMER_REQUEST,
  payload,
});
export const updateCustomerSuccess = (result) => ({
  type: Types.UPDATE_CUSTOMER_SUCCESS,
  result,
});

// DELETE
export const deleteCustomerRequest = (params) => ({
  type: Types.DELETE_CUSTOMER_REQUEST,
  params,
});
export const deleteCustomerSuccess = (result) => ({
  type: Types.DELETE_CUSTOMER_SUCCESS,
  result,
});
