import { notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import DepartmentRequest from "../mapping/Request/DepartmentRequest";
import * as Types from "../types/DepartmentTypes";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/departmentAction";
import * as FunctionService from "../services/departmentService";

function* getDepartments() {
  yield takeLatest(
    Types.GET_DEPARTMENTS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getDepartments, action?.params);
      yield put(FunctionAction.getDepartmentsSuccess(result));
    }, errorHandle(Types.GET_DEPARTMENTS_FAILURE))
  );
}

function* createDepartment() {
  yield takeLatest(
    Types.CREATE_DEPARTMENT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = new DepartmentRequest(action?.payload).exportCreate();
      const result = yield call(FunctionService.createDepartment, data);
      yield put(FunctionAction.createDepartmentSuccess(result));
      notification.success({ message: "Create Successfully" });
    }, errorHandle(Types.CREATE_DEPARTMENT_FAILURE))
  );
}

function* updateDepartment() {
  yield takeLatest(
    Types.UPDATE_DEPARTMENT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = new DepartmentRequest(action?.payload).exportUpdate();
      const result = yield call(FunctionService.updateDepartment, data);
      yield put(FunctionAction.updateDepartmentSuccess(result));
      notification.success({ message: "Update Successfully" });
    }, errorHandle(Types.UPDATE_DEPARTMENT_FAILURE))
  );
}

function* deleteDepartment() {
  yield takeLatest(
    Types.DELETE_DEPARTMENT_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteDepartment, action?.params);
      yield put(FunctionAction.deleteDepartmentSuccess(action?.params));
      notification.success({ message: "Delete Successfully" });
    }, errorHandle(Types.DELETE_DEPARTMENT_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* departmentSaga() {
  yield all([
    fork(getDepartments),
    fork(createDepartment),
    fork(updateDepartment),
    fork(deleteDepartment),
  ]);
}
