import React, { Component, useState } from "react";
import queryString from "query-string";

export const removeObjectNullFull = (state, notIn = []) => {
  if (state) {
    for (var propName in state) {
      if (
        state[propName] === null ||
        state[propName] === undefined ||
        state[propName] === "" ||
        (Array.isArray(state[propName]) &&
          (state[propName] || []).length < 1 &&
          typeof state[propName] !== "number")
      ) {
        delete state[propName];
      }
    }
  }
  return state;
};

function getArrayOfChildren(arr, existingChildren) {
  arr.forEach((o) => {
    existingChildren.push(o.path);
    o.children && getArrayOfChildren(o.children, existingChildren);
  });
  return existingChildren;
}

let output = {};
export const FindIteminArrayRecursive = (arr, value) => {
  arr.forEach((o) => {
    // let flag = false;
    if (o.path) {
      if (o.path === value) {
        output.item = o;
        output.childs =
          (o.children && getArrayOfChildren(o.children, [])) || [];
      } else if (o.children && o.children.length) {
        output = FindIteminArrayRecursive(o.children, value);
      }
    }
  });
  return output;
};

export const getPathWithParams = (path = "", params = {}) => {
  Object.keys(params).forEach((p) => {
    const searchStrParam = `\/:${p}`;
    if (searchStrParam.length > 2) {
      path = path.replace(searchStrParam, `\/${params[p]}`);
    } else {
      path = `${path}${
        queryString.stringify(params).length > 0
          ? "/" + queryString.stringify(params)
          : ""
      }`;
    }
  });
  return path;
};

/**
 * Wrapper every saga process async function* to by pass try catch block.
 * @param {function} [saga] - saga process async function*.
 * @param {function} [errorHandle] - error handle async function*.
 */
export function sagaWrapper(saga, errorHandle, customMessageErrors = "") {
  return function* (action) {
    try {
      yield saga(action);
    } catch (e) {
      yield errorHandle(e);
    }
  };
}

/**
 * Handle displaying errors in saga processes.
 * @param {function} callback - callback asyn function* after show error message.
 * @param {string} itemStringKey - item string key to get item error message: (Ex: hotel, tour, order, group,...).
 */
export function sagaErrorHandler(callback) {
  return function* (e) {
    if (callback) {
      yield callback(e);
    }
  };
}

export const formatInt = (number, defaultNum = 0) =>
  isNaN(parseInt(number)) ? defaultNum : parseInt(number);
