import { pick } from "lodash";
import { PAY_PERIOD_OPTIONS } from "../../../constants/payPeriod";

export const fieldNames = {
  id: "id",
  name: "name",
  payFrequency: "payFrequency",
  arrearsDays: "arrearsDays",
  holidayOrWeekendShift: "holidayOrWeekendShift",
  payDayOfWeek: "payDayOfWeek",
  payDayOfMonth: "payDayOfMonth",
  secondPayDayOfMonth: "secondPayDayOfMonth",
  payPeriodEndDayOfMonth: "payPeriodEndDayOfMonth",
  employees: "employees",
};

// export const fieldNames = [
//   "id",
//   "name",
//   "payFrequency",
//   "arrearsDays",
//   "holidayOrWeekendShift",
//   "payDayOfWeek",
//   "payDayOfMonth",
//   "secondPayDayOfMonth",
//   "payPeriodEndDayOfMonth",
// ].reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});

export default class PayScheduleResponse {
  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    this[fieldNames.id] = data?.id || "";
    this[fieldNames.name] = data?.name || "";
    this[fieldNames.arrearsDays] = data.arrears_days || 0;
    this[fieldNames.holidayOrWeekendShift] =
      data?.holiday_or_weekend_shift || "BEFORE";
    this[fieldNames.payDayOfWeek] = data?.pay_day_of_week || 1;
    this[fieldNames.payDayOfMonth] = data?.pay_day_of_month || "1";
    this[fieldNames.secondPayDayOfMonth] =
      data?.second_pay_day_of_month || "16";
    this[fieldNames.payFrequency] =
      data?.pay_frequency || PAY_PERIOD_OPTIONS?.WEEKLY.key;
    this[fieldNames.payPeriodEndDayOfMonth] =
      data?.pay_period_end_day_of_month || "1";
    this[fieldNames.employees] = data?.employees || [];
  }

  exportList() {
    return {
      [fieldNames.id]: this.id,
      [fieldNames.name]: this.name,
      [fieldNames.payFrequency]: this.payFrequency,
      [fieldNames.arrearsDays]: this.arrearsDays,
      [fieldNames.holidayOrWeekendShift]: this.holidayOrWeekendShift,
      [fieldNames.payDayOfWeek]: this.payDayOfWeek,
      [fieldNames.payDayOfMonth]: this.payDayOfMonth,
      [fieldNames.secondPayDayOfMonth]: this.secondPayDayOfMonth,
      [fieldNames.payPeriodEndDayOfMonth]: this.payPeriodEndDayOfMonth,
      [fieldNames.employees]: this.employees,
    };
  }

  exportItem() {
    return {
      [fieldNames.id]: this.id,
      [fieldNames.name]: this.name,
      [fieldNames.payFrequency]: this.payFrequency,
      [fieldNames.arrearsDays]: this.arrearsDays,
      [fieldNames.holidayOrWeekendShift]: this.holidayOrWeekendShift,
      [fieldNames.payDayOfWeek]: this.payDayOfWeek,
      [fieldNames.payDayOfMonth]: this.payDayOfMonth,
      [fieldNames.secondPayDayOfMonth]: this.secondPayDayOfMonth,
      [fieldNames.payPeriodEndDayOfMonth]: this.payPeriodEndDayOfMonth,
    };
  }
}
