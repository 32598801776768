import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { find } from "lodash";
import { Avatar, Badge, Button, Popover } from "antd";
import connect from "react-redux/es/connect/connect";

import routers from "../../../routers";
import Logo from "../../../assets/images/logo_light.svg";
import { signoutRequest } from "../../../data/actions/authAction";
import { useGetCompanyFromToken, useGetMe } from "../../../data/hooks/authHook";

const MenuItem = ({ data, group, setNumberMenu, id }) => {
  let itemGroup =
    (data?.items || []).length > 0
      ? (data?.items[0]?.route || "").split(".")[0]
      : "";
  let getMenu = find(routers, { group: itemGroup });

  const handleOnClick = () => {
    setNumberMenu(id);
  };

  return (
    <>
      {getMenu?.show ? (
        <li
          onClick={handleOnClick}
          className={`menu__item${group === itemGroup ? "--active" : ""}`}>
          <Link
            to={{
              pathname: getMenu?.sub_menu[0]?.path,
              state: { group: itemGroup },
            }}>
            <i className={`fa ${getMenu?.icon} me-3`} aria-hidden='true'></i>
            {getMenu?.title}
          </Link>
        </li>
      ) : null}
    </>
  );
};

const Navigation = (props) => {
  const [numberMenu, setNumberMenu] = useState("0");
  const { match, menu, group } = props;
  const path = match.path;
  const { isAdmin, isManager, isSuperAdmin } = useGetMe();

  const company = useGetCompanyFromToken();

  return (
    <div className='sider'>
      <div className='sider__header'>
        <Link to='/'>
          <img
            height='50'
            width='230'
            style={{
              // backgroundSize: "",
              objectFit: "cover",
            }}
            src={company?.logo || Logo}
            alt='real estate iq logo'
          />
        </Link>
      </div>
      <div className='sider__body'>
        <ul className='menu menu--vertical'>
          {!!menu.length &&
            menu.map((item, idx) => (
              <div key={`${idx}`}>
                {(isAdmin && item?.isAllowedAdmin) ||
                (isManager && item?.isAllowedManager) ||
                (isSuperAdmin && item?.isAllowedSuperAdmin) ||
                (!item?.isAllowedAdmin &&
                  !item?.isAllowedManager &&
                  !item?.isAllowedSuperAdmin) ? (
                  <MenuItem
                    key={`${idx}`}
                    data={item}
                    group={group}
                    numberMenu={numberMenu}
                    setNumberMenu={setNumberMenu}
                    path={path}
                  />
                ) : null}
              </div>
            ))}
        </ul>
      </div>
      <Badge.Ribbon text={isSuperAdmin ? "Super Admin" : null}>
        <div className='sider__footer'>
          <hr />
          <Popover
            content={() => (
              <Button type='text' onClick={() => props.onLogoutRequest()}>
                Logout
              </Button>
            )}>
            <div>
              <Avatar
                className='me-2'
                size={40}
                icon={
                  <img
                    alt='avatar of user'
                    src={
                      props?.profile?.user?.avatar ||
                      (props.profile?.user?.first_name &&
                      props.profile?.user?.last_name
                        ? `https://ui-avatars.com/api/?bold=true&color=ffffff&background=015a8f&name=${props.profile?.user?.first_name} ${props?.profile?.user?.last_name}`
                        : "https://ui-avatars.com/api/?bold=true&color=ffffff&background=015a8f&name=HD")
                    }
                  />
                }
              />
              <strong className='me-1'>
                {`${
                  props?.profile.user
                    ? props?.profile?.user?.first_name +
                      " " +
                      props?.profile?.user?.last_name
                    : "Username"
                }
           `}
              </strong>

              <i className='fa fa-caret-down' aria-hidden='true'></i>
            </div>
          </Popover>
        </div>
      </Badge.Ribbon>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutRequest: () => {
      dispatch(signoutRequest());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
