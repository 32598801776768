import * as Types from "../types/PayRollType";

export const getEmployeesOnPayRollRequest = (filter) => ({
  type: Types.GET_EMPLOYEES_ON_PAY_ROLL_REQUEST,
  filter,
});

export const getEmployeesOnPayRollSuccess = (result) => ({
  type: Types.GET_EMPLOYEES_ON_PAY_ROLL_SUCCESS,
  result,
});

export const getDraftPayRunsRequest = (filter) => ({
  type: Types.GET_DRAFT_PAY_RUNS_REQUEST,
  filter,
});

export const getDraftPayRunsSuccess = (result) => ({
  type: Types.GET_DRAFT_PAY_RUNS_SUCCESS,
  result,
});

export const getApprovedPayRunsRequest = (filter) => ({
  type: Types.GET_APPROVED_PAY_RUNS_REQUEST,
  filter,
});

export const getApprovedPayRunsSuccess = (result) => ({
  type: Types.GET_APPROVED_PAY_RUNS_SUCCESS,
  result,
});

export const getPaidPayRunsRequest = (filter) => ({
  type: Types.GET_PAID_PAY_RUNS_REQUEST,
  filter,
});
export const getPaidPayRunsSuccess = (result) => ({
  type: Types.GET_PAID_PAY_RUNS_SUCCESS,
  result,
});

export const approveDraftPayRunRequest = (payload) => ({
  type: Types.APPROVE_DRAFT_PAY_RUN_REQUEST,
  payload,
});

export const approveDraftPayRunSuccess = (result) => ({
  type: Types.APPROVE_DRAFT_PAY_RUN_SUCCESS,
  result,
});

export const revertApprovedPayRunRequest = (payload) => ({
  type: Types.REVERT_APPROVED_PAY_RUN_REQUEST,
  payload,
});

export const revertApprovedPayRunSuccess = (result) => ({
  type: Types.REVERT_APPROVED_PAY_RUN_SUCCESS,
  result,
});

export const addEmployeeOnPayRollRequest = (payload) => ({
  type: Types.ADD_EMPLOYEE_ON_PAY_ROLL_REQUEST,
  payload,
});

export const addEmployeeOnPayRollSuccess = (result) => ({
  type: Types.ADD_EMPLOYEE_ON_PAY_ROLL_SUCCESS,
  result,
});

export const removeEmployeeOnPayRollRequest = (payload) => ({
  type: Types.REMOVE_EMPLOYEE_ON_PAY_ROLL_REQUEST,
  payload,
});

export const removeEmployeeOnPayRollSuccess = (result) => ({
  type: Types.REMOVE_EMPLOYEE_ON_PAY_ROLL_SUCCESS,
  result,
});

export const deletePayRunRequest = (payload) => ({
  type: Types.DELETE_PAY_RUN_REQUEST,
  payload,
});

export const deletePayRunSuccess = (result) => ({
  type: Types.DELETE_PAY_RUN_SUCCESS,
  result,
});

export const updatePayRunRequest = (payload) => ({
  type: Types.UPDATE_PAY_RUN_REQUEST,
  payload,
});

export const updatePayRunSuccess = (result) => ({
  type: Types.UPDATE_PAY_RUN_SUCCESS,
  result,
});

export const updateEmployeeInPayRunRequest = (payload) => ({
  type: Types.UPDATE_EMPLOYEE_IN_PAY_RUN_REQUEST,
  payload,
});

export const updateEmployeeInPayRunSuccess = (result) => ({
  type: Types.UPDATE_EMPLOYEE_IN_PAY_RUN_SUCCESS,
  result,
});

export const addEmployeeInPayRunRequest = (payload) => ({
  type: Types.ADD_EMPLOYEES_IN_PAY_RUN_REQUEST,
  payload,
});

export const addEmployeeInPayRunSuccess = (result) => ({
  type: Types.ADD_EMPLOYEES_IN_PAY_RUN_SUCCESS,
  result,
});

export const addOffCycleRunRequest = (payload) => ({
  type: Types.ADD_OFF_CYCLE_RUN_REQUEST,
  payload,
});

export const addOffCycleRunSuccess = (result) => ({
  type: Types.ADD_OFF_CYCLE_RUN_SUCCESS,
  result,
});

export const getEarningsRequest = (payload) => ({
  type: Types.GET_EARNINGS_REQUEST,
  payload,
});

export const getEarningsSuccess = (result) => ({
  type: Types.GET_EARNINGS_SUCCESS,
  result,
});

export const getDeductionsRequest = (payload) => ({
  type: Types.GET_DEDUCTIONS_REQUEST,
  payload,
});

export const getDeductionsSuccess = (result) => ({
  type: Types.GET_DEDUCTIONS_SUCCESS,
  result,
});

export const createEarningOrDeductionRequest = (payload) => ({
  type: Types.CREATE_EARNING_OR_DEDUCTION_REQUEST,
  payload,
});

export const createEarningOrDeductionSuccess = (result) => ({
  type: Types.CREATE_EARNING_OR_DEDUCTION_SUCCESS,
  result,
});

export const updateEarningOrDeductionRequest = (payload) => ({
  type: Types.UPDATE_EARNING_OR_DEDUCTION_REQUEST,
  payload,
});

export const updateEarningOrDeductionSuccess = (result) => ({
  type: Types.UPDATE_EARNING_OR_DEDUCTION_SUCCESS,
  result,
});

export const deleteEarningOrDeductionRequest = (payload) => ({
  type: Types.DELETE_EARNING_OR_DEDUCTION_REQUEST,
  payload,
});

export const deleteEarningOrDeductionSuccess = (result) => ({
  type: Types.DELETE_EARNING_OR_DEDUCTION_SUCCESS,
  result,
});
