import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import PERMISSION from "../../../constants/permission";
import { verifyInit } from "../../../data/actions/verifyAction";
import { connect } from "react-redux";

const Component403 = (props) => {
  useEffect(() => {}, []);

  return (
    <div className='middle-box text-center animated fadeInDown'>
      <h1>403</h1>
      <h3 className='font-bold'>Error Forbidden</h3>
      <div className='error-desc'>
        You don' t have permission to access on this server
      </div>
      <Link
        to={{
          pathname: PERMISSION.HOME.VIEW.route,
          state: { group: PERMISSION.HOME.VIEW.group },
        }}
        className={`mb-2`}>
        Back Home
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authentication: state.authReducer,
  verifyAction: state.verifyReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onVerifyInit: (params) => {
      dispatch(verifyInit(params));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Component403)
);
