import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

import CustomerResponse from "../mapping/Response/CustomerResponse";

export function getCustomers(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_CUSTOMERS,
    parser: dataParser,
  }).get();
}

export function createCustomer(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_CUSTOMER,
    endpointParams: { company_id: payload?.company_id },
    parser: (data) => new CustomerResponse(data.data.result).exportItem(),
  }).post(payload);
}

export function updateCustomer(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_CUSTOMER,
    endpointParams: { employee_id: payload?.id },
    parser: (data) => new CustomerResponse(data.data.result).exportItem(),
  }).put(payload);
}

export function deleteCustomer(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_CUSTOMER,
    endpointParams: { id: params?.id },
  }).delete();
}

function dataParser(data = {}) {
  return (data.data.result || []).map((item) =>
    new CustomerResponse(item).exportList()
  );
}
