import { filter, unionBy } from "lodash";
import * as Types from "../types/TimeOffType";

const initialState = {
  categories: [],
  peopleOnLeaveDays: {},
  item: {
    timeOffs: [],
    timeOffHistories: [],
  },
};

const timeOffReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types?.GET_TIME_OFF_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action?.result,
      };
    case Types.CREATE_TIME_OFF_CATEGORY_SUCCESS: {
      const categories = [action?.result, ...state?.categories];
      return {
        ...state,
        categories,
      };
    }
    case Types.UPDATE_TIME_OFF_CATEGORY_SUCCESS: {
      const categories = state?.categories?.map((x) => {
        if (x?.id === action?.result?.id) {
          return action?.result;
        }
        return x;
      });
      return {
        ...state,
        categories,
      };
    }
    case Types.DELETE_TIME_OFF_CATEGORY_SUCCESS: {
      const categories = state?.categories?.filter(
        (category) => category?.id !== action?.result?.id
      );
      return {
        ...state,
        categories,
      };
    }
    case Types.GET_PEOPLE_ON_LEAVE_DAYS_SUCCESS: {
      const peopleOnLeaveDays = {
        ...state?.peopleOnLeaveDays,
        ...action?.result,
      };
      return {
        ...state,
        peopleOnLeaveDays,
      };
    }
    case Types.GET_EMPLOYEE_TIME_OFF_HISTORIES_SUCCESS: {
      const item = { ...state?.item, timeOffHistories: action?.result };
      return {
        ...state,
        item,
      };
    }
    default:
      return state;
  }
};

export default timeOffReducer;
