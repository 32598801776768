import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";
import PayScheduleResponse from "../mapping/Response/PayScheduleResponse";

export function getPaySchedules() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.PAY_SCHEDULE_GET_LIST,
    parser: dataParser,
  }).get();
}
export function addPaySchedule(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.PAY_SCHEDULE_ADD_ITEM,
    parser: (data) => new PayScheduleResponse(data?.data?.result).exportItem(),
  }).post(payload);
}
export function editPaySchedule(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.PAY_SCHEDULE_EDIT_ITEM,
    endpointParams: { id: payload?.id },
    parser: (data) => new PayScheduleResponse(data?.data?.result).exportItem(),
  }).put(payload);
}
export function deletePaySchedule(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.PAY_SCHEDULE_DELETE_ITEM,
    endpointParams: { id: params?.id },
  }).delete();
}

function dataParser(data = {}) {
  return (data.data.result || []).map((item) =>
    new PayScheduleResponse(item).exportList()
  );
}
