export const GET_PAY_SCHEDULES = "GET_PAY_SCHEDULES";
export const CREATE_PAY_SCHEDULE = "CREATE_PAY_SCHEDULE";
export const UPDATE_PAY_SCHEDULE = "UPDATE_PAY_SCHEDULE";
export const DELETE_PAY_SCHEDULE = "DELETE_PAY_SCHEDULE";

export const GET_PAY_SCHEDULES_REQUEST = "GET_PAY_SCHEDULES_REQUEST";
export const CREATE_PAY_SCHEDULE_REQUEST = "CREATE_PAY_SCHEDULE_REQUEST";
export const UPDATE_PAY_SCHEDULE_REQUEST = "UPDATE_PAY_SCHEDULE_REQUEST";
export const DELETE_PAY_SCHEDULE_REQUEST = "DELETE_PAY_SCHEDULE_REQUEST";

export const GET_PAY_SCHEDULES_SUCCESS = "GET_PAY_SCHEDULES_SUCCESS";
export const UPDATE_PAY_SCHEDULE_SUCCESS = "UPDATE_PAY_SCHEDULE_SUCCESS";
export const CREATE_PAY_SCHEDULE_SUCCESS = "CREATE_PAY_SCHEDULE_SUCCESS";
export const DELETE_PAY_SCHEDULE_SUCCESS = "DELETE_PAY_SCHEDULE_SUCCESS";

export const GET_PAY_SCHEDULES_FAILURE = "GET_PAY_SCHEDULES_FAILURE ";
export const UPDATE_PAY_SCHEDULE_FAILURE = "UPDATE_PAY_SCHEDULE_FAILURE";
export const CREATE_PAY_SCHEDULE_FAILURE = "CREATE_PAY_SCHEDULE_FAILURE";
export const DELETE_PAY_SCHEDULE_FAILURE = "DELETE_PAY_SCHEDULE_FAILURE";
