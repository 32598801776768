import * as Types from "../types/BenefitType";

export const getBenefitPlansRequest = (filters) => ({
  type: Types.GET_BENEFIT_PLANS_REQUEST,
  filters,
});
export const getBenefitPlansSuccess = (result) => ({
  type: Types.GET_BENEFIT_PLANS_SUCCESS,
  result,
});
export const createBenefitPlanRequest = (payload) => ({
  type: Types.CREATE_BENEFIT_PLAN_REQUEST,
  payload,
});
export const createBenefitPlanSuccess = (result) => ({
  type: Types.CREATE_BENEFIT_PLAN_SUCCESS,
  result,
});
export const updateBenefitPlanRequest = (payload) => ({
  type: Types.UPDATE_BENEFIT_PLAN_REQUEST,
  payload,
});
export const updateBenefitPlanSuccess = (result) => ({
  type: Types.UPDATE_BENEFIT_PLAN_SUCCESS,
  result,
});
export const deleteBenefitPlanRequest = (params) => ({
  type: Types.DELETE_BENEFIT_PLAN_REQUEST,
  params,
});
export const deleteBenefitPlanSuccess = (result) => ({
  type: Types.DELETE_BENEFIT_PLAN_SUCCESS,
  result,
});

export const getBenefitCategoriesRequest = (filters) => ({
  type: Types.GET_BENEFIT_CATEGORIES_REQUEST,
  filters,
});
export const getBenefitCategoriesSuccess = (result) => ({
  type: Types.GET_BENEFIT_CATEGORIES_SUCCESS,
  result,
});
export const createBenefitCategoryRequet = (payload) => ({
  type: Types.CREATE_BENEFIT_CATEGORY_REQUEST,
  payload,
});
export const createBenefitCategorySuccess = (result) => ({
  type: Types.CREATE_BENEFIT_CATEGORY_SUCCESS,
  result,
});
export const updateBenefitCategoryRequest = (payload) => ({
  type: Types.UPDATE_BENEFIT_CATEGORY_REQUEST,
  payload,
});
export const updateBenefitCategorySuccess = (result) => ({
  type: Types.UPDATE_BENEFIT_CATEGORY_SUCCESS,
  result,
});
export const deleteBenefitCategoryRequest = (params) => ({
  type: Types.DELETE_BENEFIT_CATEGORY_REQUEST,
  params,
});
export const deleteBenefitCategorySuccess = (result) => ({
  type: Types.DELETE_BENEFIT_CATEGORY_SUCCESS,
  result,
});
