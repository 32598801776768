import * as Types from "../types/EmployeeType";
import { PAGINATION } from "../../constants/define";
import { uniqBy } from "lodash";

const initialState = {
  all: [],
  orgEmployees: [],
  personalInfo: {},
  jobInfo: {},
  compensation: {},
  employementStatus: {},
  employeeTimeOffs: [],
  employeeBenefits: [],
  emergencyContacts: [],
  payStubs: [],
  payStubItem: {},
  data: {
    result: [],
    page_size: PAGINATION.page_size,
    page_index: PAGINATION.page_index,
    total: PAGINATION.total,
    total_page: PAGINATION.total_page,
  },
  maxEmployee: [],
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_EMPLOYEES_SUCCESS: {
      const all = uniqBy([...action?.result?.data], "id");
      const newMaxEmployee = all.length > state.maxEmployee.length ? all : state.maxEmployee;
      return {
        ...state,
        all,
        maxEmployee: newMaxEmployee,
      };
    }
    case Types.GET_ORG_EMPLOYEES_SUCCESS: {
      const orgEmployees = uniqBy(action?.result, "id");
      return {
        ...state,
        orgEmployees,
      };
    }
    case Types.CREATE_EMPLOYEE_SUCCESS: {
      // const all = [action?.result, ...state?.all];
      // return { ...state, all };
      return state;
    }
    case Types.GET_EMPLOYEE_PERSONAL_INFO_SUCCESS: {
      const personalInfo = {
        ...action?.result?.employee,
        ...action?.result?.personal_info,
      };
      return {
        ...state,
        personalInfo,
      };
    }
    case Types.UPDATE_EMPLOYEE_PERSONAL_INFO_SUCCESS: {
      const personalInfo = action?.result;
      return {
        ...state,
        personalInfo,
      };
    }
    case Types.GET_EMPLOYEE_JOB_INFO_SUCCESS: {
      const jobInfo = action?.result;
      return {
        ...state,
        jobInfo,
      };
    }
    case Types.CREATE_EMPLOYEE_JOB_INFO_SUCCESS: {
      return state;
    }
    case Types.GET_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS: {
      const employementStatus = action?.result;
      return {
        ...state,
        employementStatus,
      };
    }
    case Types.GET_EMPLOYEE_COMPENSATION_SUCCESS: {
      const compensation = action?.result;
      return {
        ...state,
        compensation,
      };
    }
    case Types.CREATE_EMPLOYEE_COMPENSATION_SUCCESS: {
      return state;
    }
    case Types.DELETE_EMPLOYEE_COMPENSATION_SUCCESS: {
      const compensation = state?.compensation?.filter(
        (item) => item?.x !== action?.result?.id
      );
      return {
        ...state,
        compensation,
      };
    }
    case Types?.GET_EMPLOYEE_BENEFITS_SUCCESS: {
      const employeeBenefits = action?.result;
      return {
        ...state,
        employeeBenefits,
      };
    }
    case Types.GET_EMPLOYEE_EMERGENCY_CONTACTS_SUCCESS: {
      const emergencyContacts = action?.result?.emergency_contacts;
      return {
        ...state,
        emergencyContacts,
      };
    }
    case Types.UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS: {
      const emergencyContacts = state?.emergencyContacts?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return {
        ...state,
        emergencyContacts,
      };
    }
    case Types.DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS: {
      const emergencyContacts = state?.emergencyContacts?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return {
        ...state,
        emergencyContacts,
      };
    }

    case Types.GET_EMPLOYEE_TIME_OFFS_SUCCESS: {
      const employeeTimeOffs = action?.result;
      return {
        ...state,
        employeeTimeOffs,
      };
    }
    case Types.UPDATE_EMPLOYEE_TIME_OFF_SUCCESS: {
      const employeeTimeOffs = state?.employeeTimeOffs?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return {
        ...state,
        employeeTimeOffs,
      };
    }
    case Types.GET_EMPLOYEE_PAY_STUBS_SUCCESS: {
      const payStubs = action?.result?.pay_stubs;
      return {
        ...state,
        payStubs,
      };
    }
    case Types.DELETE_EMPLOYEE_TIME_OFF_SUCCESS: {
      const employeeTimeOffs = state?.employeeTimeOffs?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return {
        ...state,
        employeeTimeOffs,
      };
    }

    case Types.UPLOAD_AVATAR_SUCCESS: {
      const personalInfo = {
        ...(state?.personalInfo || {}),
        avatar: action?.result?.file_url || "",
      };
      return { ...state, personalInfo };
    }
    case Types.CHANGE_EMPLOYEE_ROLE_SUCCESS: {
      const personalInfo = {
        ...(state?.personalInfo || {}),
        role: action?.result?.role,
      };
      return { ...state, personalInfo };
    }
    case Types.CHANGE_PASSWORD_SUCCESS: {
      return state;
    }

    case Types.RESET_PASSWORD_SUCCESS: {
      return state;
    }

    case Types.CHANGE_STATUS_EMPLOYEE_SUCCESS: {
      const { id, status } = action?.result;

      const all = state?.all?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            status
          }
        }
        return item;
      });
      console.log("=======all=====", {all})
      return {
        ...state,
        all,
      };
    }

    default:
      return state;
  }
};
export default employeeReducer;
