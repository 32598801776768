import * as Types from "../types/AdminAccountTypes";

const initialState = {
  list: [],
};

const adminAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_ADMIN_ACCOUNTS_SUCCESS: {
      const list = action?.result || [];
      return {
        ...state,
        list,
      };
    }
    case Types.CREATE_ADMIN_ACCOUNT_SUCCESS: {
      const list = [action?.result, ...state?.list];
      return {
        ...state,
        list,
      };
    }
    case Types.UPDATE_ADMIN_ACCOUNT_SUCCESS: {
      const list = state?.list?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return {
        ...state,
        list,
      };
    }
    case Types.DELETE_ADMIN_ACCOUNT_SUCCESS: {
      const list = state?.list?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return { ...state, list };
    }
    default:
      return state;
  }
};
export default adminAccountReducer;
