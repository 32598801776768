import { notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import * as Types from "../types/TimeOffType";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/timeOffAction";
import * as FunctionService from "../services/timeOffService";

function* getTimeOffCategories() {
  yield takeLatest(
    Types.GET_TIME_OFF_CATEGORIES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getTimeOffCategories);
      yield put(FunctionAction.getTimeOffCategoriesSuccess(result));
    }, errorHandle(Types.GET_TIME_OFF_CATEGORIES_FAILURE))
  );
}

function* createTimeOffCategory() {
  yield takeLatest(
    Types.CREATE_TIME_OFF_CATEGORY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createTimeOffCategory,
        action?.payload
      );
      yield put(FunctionAction.createTimeOffCategorySuccess(result));
    }, errorHandle(Types.CREATE_TIME_OFF_CATEGORY_FAILURE))
  );
}

function* updateTimeOffCategory() {
  yield takeLatest(
    Types.UPDATE_TIME_OFF_CATEGORY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateTimeOffCategory,
        action?.payload
      );
      yield put(FunctionAction.updateTimeOffCategorySuccess(result));
      notification.success({ message: "Update Success" });
    }, errorHandle(Types.UPDATE_TIME_OFF_CATEGORY_FAILURE))
  );
}

function* deleteTimeOffCategory() {
  yield takeLatest(
    Types.DELETE_TIME_OFF_CATEGORY_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteTimeOffCategory, action?.params);
      yield put(FunctionAction.deleteTimeOffCategorySuccess(action?.params));
      notification.success({ message: "Success" });
    }, errorHandle(Types.DELETE_TIME_OFF_CATEGORY_FAILURE))
  );
}

function* sendTimeOff() {
  yield takeLatest(
    Types.SEND_TIME_OFF_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.sendTimeOff, action?.payload);
      yield put(FunctionAction.requestTimeOffSuccess(result));
      notification.success({ message: "Success" });
    }, errorHandle(Types.SEND_TIME_OFF_FAILURE))
  );
}

function* getEmployeeTimeOffsHistories() {
  yield takeLatest(
    Types.GET_EMPLOYEE_TIME_OFF_HISTORIES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getEmployeeTimeOffsHistories,
        action?.params
      );
      yield put(FunctionAction.getEmployeeTimeOffsHistoriesSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_TIME_OFF_HISTORIES_FAILURE))
  );
}

function* getPeopleOnLeaveDays() {
  yield takeLatest(
    Types.GET_PEOPLE_ON_LEAVE_DAYS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getPeopleOnLeaveDays,
        action?.params
      );
      yield put(FunctionAction.getPeopleOnLeaveDaysSuccess(result));
    }, errorHandle(Types.GET_PEOPLE_ON_LEAVE_DAYS_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* timeOffSaga() {
  yield all([
    fork(getTimeOffCategories),
    fork(createTimeOffCategory),
    fork(updateTimeOffCategory),
    fork(deleteTimeOffCategory),
    fork(sendTimeOff),
    fork(getPeopleOnLeaveDays),
    fork(getEmployeeTimeOffsHistories),
  ]);
}
