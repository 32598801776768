import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";
import User from "../mapping/User";

export function signin(email, password, captcha) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.SIGNIN,
  }).post({ email, password });
}

export function resgister(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.REGISTER,
    parser: (data) => new User(data.data.result),
  }).post(data);
}

export function signout() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.SIGNOUT,
  }).post({});
}

export function getUserProfile() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.ME,
    parser: (data) => new User(data.data.result),
  }).get();
}

export function update(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_PROFILE,
    endpointParams: { id: data?.id },
  }).put(data);
}

export function refreshToken(refreshToken) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.REFRESH,
    withoutAuth: true,
  }).post({ refreshToken });
}
