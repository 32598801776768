import { pick } from "lodash";

export default class DepartmentRequest {
  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    this.id = data?.id || "";
    this.name = data?.name || "";
  }

  exportCreate() {
    return pick(this, ["id", "name"]);
  }

  exportUpdate() {
    return pick(this, ["id", "name"]);
  }
}
