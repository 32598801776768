import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import logger from './logger';
import myReducer from '../reducers/index';
import rootSaga from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();
let paramsCreateStore;
if(process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging" ){
    paramsCreateStore = createStore(myReducer,applyMiddleware(sagaMiddleware, logger()));
}else{
    const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;
    paramsCreateStore = createStore(myReducer, composeEnhancers(applyMiddleware(sagaMiddleware, logger())));
}

export default paramsCreateStore;

sagaMiddleware.run(rootSaga);
