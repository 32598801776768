import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/Verify";
import * as Func from "../../utils/functions";
import * as VerifyService from "../services/verifyService";
import * as VerifyActions from "../actions/verifyAction";

function* verifyRoute() {
  yield takeLatest(
    Types.VERIFY_ROUTE_ACTION,
    Func.sagaWrapper(function* (action) {
      const result = yield call(VerifyService.verifyRoute, action.params);
      yield put(VerifyActions.verifyRouteSuccess(result));
    }, errorHandle(Types.VERIFY_FAILED))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* verifySaga() {
  yield all([fork(verifyRoute)]);
}
