export const GET_ADMIN_ACCOUNTS = "GET_ADMIN_ACCOUNTS";
export const GET_ADMIN_ACCOUNTS_REQUEST = "GET_ADMIN_ACCOUNTS_REQUEST";
export const GET_ADMIN_ACCOUNTS_SUCCESS = "GET_ADMIN_ACCOUNTS_SUCCESS";
export const GET_ADMIN_ACCOUNTS_FAILURE = "GET_ADMIN_ACCOUNTS_FAILURE";

export const CREATE_ADMIN_ACCOUNT = "CREATE_ADMIN_ACCOUNT";
export const CREATE_ADMIN_ACCOUNT_REQUEST = "CREATE_ADMIN_ACCOUNT_REQUEST";
export const CREATE_ADMIN_ACCOUNT_SUCCESS = "CREATE_ADMIN_ACCOUNT_SUCCESS";
export const CREATE_ADMIN_ACCOUNT_FAILURE = "CREATE_ADMIN_ACCOUNT_FAILURE";

export const UPDATE_ADMIN_ACCOUNT = "UPDATE_ADMIN_ACCOUNT";
export const UPDATE_ADMIN_ACCOUNT_REQUEST = "UPDATE_ADMIN_ACCOUNT_REQUEST";
export const UPDATE_ADMIN_ACCOUNT_SUCCESS = "UPDATE_ADMIN_ACCOUNT_SUCCESS";
export const UPDATE_ADMIN_ACCOUNT_FAILURE = "UPDATE_ADMIN_ACCOUNT_FAILURE";

export const DELETE_ADMIN_ACCOUNT = "DELETE_ADMIN_ACCOUNT";
export const DELETE_ADMIN_ACCOUNT_REQUEST = "DELETE_ADMIN_ACCOUNT_REQUEST";
export const DELETE_ADMIN_ACCOUNT_SUCCESS = "DELETE_ADMIN_ACCOUNT_SUCCESS";
export const DELETE_ADMIN_ACCOUNT_FAILURE = "DELETE_ADMIN_ACCOUNT_FAILURE";

export const BLOCK_ADMIN_ACCOUNT = "BLOCK_ADMIN_ACCOUNT";
export const BLOCK_ADMIN_ACCOUNT_REQUEST = "BLOCK_ADMIN_ACCOUNT_REQUEST";
export const BLOCK_ADMIN_ACCOUNT_SUCCESS = "BLOCK_ADMIN_ACCOUNT_SUCCESS";
export const BLOCK_ADMIN_ACCOUNT_FAILURE = "BLOCK_ADMIN_ACCOUNT_FAILURE";
