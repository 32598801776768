import * as Types from "../types/FileType";

const initialState = {
  folders: [],
  files: [],
};

const benefitReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_FILES_SUCCESS:
      return {
        ...state,
        files: action?.result,
      };
    case Types.DELETE_FILE_SUCCESS: {
      console.log("action is ", { action });
      const files = state?.files?.filter(
        (file) => file?.file_name !== action?.result?.file_name
      );
      return {
        ...state,
        files,
      };
    }
    case Types.GET_FOLDERS_SUCCESS:
      return {
        ...state,
        folders: action?.result,
      };
    case Types.CREATE_FOLDER_SUCCESS: {
      const folders = [...state?.folders, action?.result];
      return {
        ...state,
        folders,
      };
    }
    case Types.UPDATE_FOLDER_SUCCESS: {
      const folders = state?.folders?.map((folder) => {
        if (folder?.id === action?.result?.id) {
          return action?.result;
        }
        return folder;
      });
      return {
        ...state,
        folders,
      };
    }
    case Types.DELETE_FOLDER_SUCCESS:
      const folders = state?.folders?.filter(
        (folder) => folder?.id !== action?.result?.id
      );
      return {
        ...state,
        folders,
      };
    case Types.UPLOAD_FILE_SUCCESS:
      const onSuccess = action?.result?.onSuccess;
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
      const files = [action?.result?.result, ...state?.files];
      return {
        ...state,
        files,
      };
    default:
      return state;
  }
};
export default benefitReducer;
