import * as Types from "../types/AuthType";

export const signinRequest = (params) => ({
  type: Types.SIGNIN_REQUEST,
  params,
});
export const signinSuccess = (data) => ({ type: Types.SIGNIN_SUCCESS, data });

export const checkUserRequest = (token) => ({
  type: Types.CHECK_USER_REQUEST,
  token,
});
export const checkUserSuccess = (result) => ({
  type: Types.CHECK_USER_SUCCESS,
  result,
});

export const signoutRequest = (params) => ({
  type: Types.SIGNOUT_REQUEST,
  params,
});
export const signoutSuccess = (data) => ({ type: Types.SIGNOUT_SUCCESS, data });

export const updateProfileRequest = (params) => ({
  type: Types.UPDATE_PROFILE_REQUEST,
  params,
});
export const updateProfileSuccess = (updateProfile) => ({
  type: Types.UPDATE_PROFILE_SUCCESS,
  updateProfile,
});

export const refreshTokenRequest = (refreshToken) => ({
  type: Types.REFRESH_TOKEN_REQUEST,
  refreshToken,
});
