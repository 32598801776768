import * as Types from "../types/PayRollType";

const initialState = {
  employeesOnPayRoll: [],
  draftPayRuns: [],
  approvedPayRuns: [],
  paiedPayRuns: [],
  earnings: [],
  deductions: [],
};

const payRollReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_EMPLOYEES_ON_PAY_ROLL_SUCCESS: {
      const employeesOnPayRoll = action?.result;
      return {
        ...state,
        employeesOnPayRoll,
      };
    }
    case Types.GET_DRAFT_PAY_RUNS_SUCCESS: {
      const draftPayRuns = action?.result;
      return {
        ...state,
        draftPayRuns,
      };
    }
    case Types.GET_APPROVED_PAY_RUNS_SUCCESS: {
      const approvedPayRuns = action?.result;
      return {
        ...state,
        approvedPayRuns,
      };
    }
    case Types.GET_PAID_PAY_RUNS_SUCCESS: {
      const paidPayRuns = action?.result;
      return {
        ...state,
        paidPayRuns,
      };
    }
    case Types.APPROVE_DRAFT_PAY_RUN_SUCCESS: {
      const draftPayRuns = state?.draftPayRuns?.filter(
        (item) => item?.id !== action?.result?.id
      );
      const approvedPayRuns = [action?.result, ...state?.approvedPayRuns];
      return {
        ...state,
        draftPayRuns,
        approvedPayRuns,
      };
    }
    case Types.REVERT_APPROVED_PAY_RUN_SUCCESS: {
      const draftPayRuns = [action?.result, ...state?.draftPayRuns];
      const approvedPayRuns = state?.approvedPayRuns?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return {
        ...state,
        draftPayRuns,
        approvedPayRuns,
      };
    }
    case Types.UPDATE_EMPLOYEE_IN_PAY_RUN_SUCCESS: {
      const payRun = state?.draftPayRuns?.find(
        (item) => action?.result?.payRunId === item?.id
      );
      const draftPayRuns = state?.draftPayRuns?.map((item) => {
        if (item?.id === payRun?.id) {
          return {
            ...payRun,
            employees: payRun?.employees?.map((item) => {
              if (
                (item?.employee_payrun_id || item?.id) === action?.result?.id
              ) {
                return action?.result;
              }
              return item;
            }),
          };
        }
        return item;
      });
      return {
        ...state,
        draftPayRuns,
      };
    }
    case Types.ADD_EMPLOYEE_ON_PAY_ROLL_SUCCESS: {
      const employeesOnPayRoll = state?.employeesOnPayRoll?.map((employee) => {
        if (employee?.id === action?.result?.id) {
          return { ...employee, include_in_runs: 1 };
        }
        return employee;
      });
      return { ...state, employeesOnPayRoll };
    }
    case Types.REMOVE_EMPLOYEE_ON_PAY_ROLL_SUCCESS: {
      const employeesOnPayRoll = state?.employeesOnPayRoll?.map((employee) => {
        if (employee?.id === action?.result?.id) {
          return { ...employee, include_in_runs: 0 };
        }
        return employee;
      });
      return { ...state, employeesOnPayRoll };
    }
    case Types.UPDATE_PAY_RUN_SUCCESS: {
      const draftPayRuns = state?.draftPayRuns?.filter(
        (payRun) => payRun?.id !== action?.result?.id
      );
      const approvedPayRuns = [action?.result, ...state?.approvedPayRuns];
      return { ...state, draftPayRuns, approvedPayRuns };
    }
    case Types.DELETE_PAY_RUN_SUCCESS: {
      const draftPayRuns = state?.draftPayRuns?.filter(
        (payRun) => payRun?.id !== action?.result?.id
      );
      return { ...state, draftPayRuns };
    }
    case Types?.ADD_OFF_CYCLE_RUN_SUCCESS: {
      const draftPayRuns = [action?.result, ...state?.draftPayRuns];
      return {
        ...state,
        draftPayRuns,
      };
    }
    case Types?.GET_EARNINGS_SUCCESS: {
      const earnings = action?.result || state?.earnings;
      return { ...state, earnings };
    }
    case Types?.GET_DEDUCTIONS_SUCCESS: {
      const deductions = action?.result || state?.deductions;
      return { ...state, deductions };
    }
    default:
      return state;
  }
};
export default payRollReducer;
