export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAILURE = "GET_COMPANY_FAILURE";

export const CREATE_COMPANY = "CREATE_COMPANY";
export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const CHANGE_STATUS_COMPANY = "CHANGE_STATUS_COMPANY";
export const CHANGE_STATUS_COMPANY_REQUEST = "CHANGE_STATUS_COMPANY_REQUEST";
export const CHANGE_STATUS_COMPANY_SUCCESS = "CHANGE_STATUS_COMPANY_SUCCESS";
export const CHANGE_STATUS_COMPANY_FAILURE = "CHANGE_STATUS_COMPANY_FAILURE";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const UPLOAD_COMPANY_LOGO = "UPLOAD_COMPANY_LOGO";
export const UPLOAD_COMPANY_LOGO_REQUEST = "UPLOAD_COMPANY_LOGO_REQUEST";
export const UPLOAD_COMPANY_LOGO_SUCCESS = "UPLOAD_COMPANY_LOGO_SUCCESS";
export const UPLOAD_COMPANY_LOGO_FAILURE = "UPLOAD_COMPANY_LOGO_FAILURE";
