import { message, notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import * as Types from "../types/BenefitType";
import * as Func from "../../utils/functions";
import TAG_DEFINE from "../../constants/common";
import * as FunctionAction from "../actions/benefitAction";
import * as FunctionService from "../services/benefitService";

function* getBenefitPlans() {
  yield takeLatest(
    Types.GET_BENEFIT_PLANS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getBenefitPlans);
      yield put(FunctionAction.getBenefitPlansSuccess(result));
    }, errorHandle(Types.GET_BENEFIT_PLANS_FAILURE))
  );
}
function* createBenefitPlan() {
  yield takeLatest(
    Types.CREATE_BENEFIT_PLAN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createBenefitPlan,
        action?.payload
      );
      message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
      yield put(FunctionAction.createBenefitPlanSuccess(result));
      yield put(FunctionAction.getBenefitPlansRequest());
      // notification.success({ message: "Success" });
    }, errorHandle(Types.CREATE_BENEFIT_PLAN_FAILURE))
  );
}
function* updateBenefitPlan() {
  yield takeLatest(
    Types.UPDATE_BENEFIT_PLAN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateBenefitPlan,
        action?.payload
      );
      yield put(FunctionAction.updateBenefitPlanSuccess(result));
      yield put(FunctionAction.getBenefitPlansRequest());
      notification.success({ message: "Update Success" });
    }, errorHandle(Types.UPDATE_BENEFIT_PLAN_SUCCESS))
  );
}
function* deleteBenefitPlan() {
  yield takeLatest(
    Types.DELETE_BENEFIT_PLAN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.deleteBenefitPlan,
        action?.params
      );
      yield put(FunctionAction.deleteBenefitPlanSuccess(action?.params));
      notification.success({ message: "Success" });
    }, errorHandle(Types.DELETE_BENEFIT_PLAN_FAILURE))
  );
}

function* getBenefitCategories() {
  yield takeLatest(
    Types.GET_BENEFIT_CATEGORIES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getBenefitCategories);
      yield put(FunctionAction.getBenefitCategoriesSuccess(result));
    }, errorHandle(Types.GET_BENEFIT_CATEGORIES_FAILURE))
  );
}

function* createBenefitCategory() {
  yield takeLatest(
    Types.CREATE_BENEFIT_CATEGORY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createBenefitCategory,
        action.payload
      );
      yield put(FunctionAction.createBenefitCategorySuccess(result));
      notification.success({ message: "Create Benefit Category Success" });
    }, errorHandle(Types.CREATE_BENEFIT_CATEGORY_FAILURE))
  );
}

function* updateBenefiteCategory() {
  yield takeLatest(
    Types.UPDATE_BENEFIT_CATEGORY_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateBenefitCategory,
        action.payload
      );
      yield put(FunctionAction.updateBenefitCategorySuccess(result));
      notification.success({ message: "Update Benefit Category Success" });
    }, errorHandle(Types.UPDATE_BENEFIT_CATEGORY_FAILURE))
  );
}

function* deleteBenefitCategory() {
  yield takeLatest(
    Types.DELETE_BENEFIT_CATEGORY_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteBenefitCategory, action.params);
      yield put(FunctionAction.deleteBenefitCategorySuccess(action?.params));
      notification.success({ message: "Success" });
    }, errorHandle(Types.DELETE_BENEFIT_CATEGORY_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* benefitSaga() {
  yield all([
    fork(getBenefitPlans),
    fork(createBenefitPlan),
    fork(updateBenefitPlan),
    fork(deleteBenefitPlan),

    fork(getBenefitCategories),
    fork(createBenefitCategory),
    fork(updateBenefiteCategory),
    fork(deleteBenefitCategory),
  ]);
}
