export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";

export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const GET_EMPLOYEE_PERSONAL_INFO = "GET_EMPLOYEE_PERSONAL_INFO";
export const GET_EMPLOYEE_PERSONAL_INFO_REQUEST =
  "GET_EMPLOYEE_PERSONAL_INFO_REQUEST";
export const GET_EMPLOYEE_PERSONAL_INFO_SUCCESS =
  "GET_EMPLOYEE_PERSONAL_INFO_SUCCESS";
export const GET_EMPLOYEE_PERSONAL_INFO_FAILURE =
  "GET_EMPLOYEE_PERSONAL_INFO_FAILURE";

export const UPDATE_EMPLOYEE_PERSONAL_INFO = "UPDATE_EMPLOYEE_PERSONAL_INFO";
export const UPDATE_EMPLOYEE_PERSONAL_INFO_REQUEST =
  "UPDATE_EMPLOYEE_PERSONAL_INFO_REQUEST";
export const UPDATE_EMPLOYEE_PERSONAL_INFO_SUCCESS =
  "UPDATE_EMPLOYEE_PERSONAL_INFO_SUCCESS";
export const UPDATE_EMPLOYEE_PERSONAL_INFO_FAILURE =
  "UPDATE_EMPLOYEE_PERSONAL_INFO_FAILURE";

export const GET_EMPLOYEE_JOB_INFO = "GET_EMPLOYEE_JOB_INFO";
export const GET_EMPLOYEE_JOB_INFO_REQUEST = "GET_EMPLOYEE_JOB_INFO_REQUEST";
export const GET_EMPLOYEE_JOB_INFO_SUCCESS = "GET_EMPLOYEE_JOB_INFO_SUCCESS";
export const GET_EMPLOYEE_JOB_INFO_FAILURE = "GET_EMPLOYEE_JOB_INFO_FAILURE";

export const CREATE_EMPLOYEE_JOB_INFO = "CREATE_EMPLOYEE_JOB_INFO";
export const CREATE_EMPLOYEE_JOB_INFO_REQUEST =
  "CREATE_EMPLOYEE_JOB_INFO_REQUEST";
export const CREATE_EMPLOYEE_JOB_INFO_SUCCESS =
  "CREATE_EMPLOYEE_JOB_INFO_SUCCESS";
export const CREATE_EMPLOYEE_JOB_INFO_FAILURE =
  "CREATE_EMPLOYEE_JOB_INFO_FAILURE";

export const DELETE_EMPLOYEE_JOB_INFO = "DELETE_EMPLOYEE_JOB_INFO";
export const DELETE_EMPLOYEE_JOB_INFO_REQUEST =
  "DELETE_EMPLOYEE_JOB_INFO_REQUEST";
export const DELETE_EMPLOYEE_JOB_INFO_SUCCESS =
  "DELETE_EMPLOYEE_JOB_INFO_SUCCESS";
export const DELETE_EMPLOYEE_JOB_INFO_FAILURE =
  "DELETE_EMPLOYEE_JOB_INFO_FAILURE";

export const GET_EMPLOYEE_EMPLOYMENT_STATUS = "GET_EMPLOYEE_EMPLOYMENT_STATUS";
export const GET_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST =
  "GET_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST";
export const GET_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS =
  "GET_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS";
export const GET_EMPLOYEE_EMPLOYMENT_STATUS_FAILURE =
  "GET_EMPLOYEE_EMPLOYMENT_STATUS_FAILURE";

export const CREATE_EMPLOYMENT_STATUS = "CREATE_EMPLOYMENT_STATUS";
export const CREATE_EMPLOYMENT_STATUS_REQUEST =
  "CREATE_EMPLOYMENT_STATUS_REQUEST";
export const CREATE_EMPLOYMENT_STATUS_SUCCESS =
  "CREATE_EMPLOYMENT_STATUS_SUCCESS";
export const CREATE_EMPLOYMENT_STATUS_FAILURE =
  "CREATE_EMPLOYMENT_STATUS_FAILURE";

export const DELETE_EMPLOYEE_EMPLOYMENT_STATUS =
  "DELETE_EMPLOYEE_EMPLOYMENT_STATUS";
export const DELETE_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST =
  "DELETE_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST";
export const DELETE_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS =
  "DELETE_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS";
export const DELETE_EMPLOYEE_EMPLOYMENT_STATUS_FAILURE =
  "DELETE_EMPLOYEE_EMPLOYMENT_STATUS_FAILURE";

export const GET_EMPLOYEE_COMPENSATION = "GET_EMPLOYEE_COMPENSATION";
export const GET_EMPLOYEE_COMPENSATION_REQUEST =
  "GET_EMPLOYEE_COMPENSATION_REQUEST";
export const GET_EMPLOYEE_COMPENSATION_SUCCESS =
  "GET_EMPLOYEE_COMPENSATION_SUCCESS";
export const GET_EMPLOYEE_COMPENSATION_FAILURE =
  "GET_EMPLOYEE_COMPENSATION_FAILURE";

export const CREATE_EMPLOYEE_COMPENSATION = "CREATE_EMPLOYEE_COMPENSATION";
export const CREATE_EMPLOYEE_COMPENSATION_REQUEST =
  "CREATE_EMPLOYEE_COMPENSATION_REQUEST";
export const CREATE_EMPLOYEE_COMPENSATION_SUCCESS =
  "CREATE_EMPLOYEE_COMPENSATION_SUCCESS";
export const CREATE_EMPLOYEE_COMPENSATION_FAILURE =
  "CREATE_EMPLOYEE_COMPENSATION_FAILURE";

export const DELETE_EMPLOYEE_COMPENSATION = "DELETE_EMPLOYEE_COMPENSATION";
export const DELETE_EMPLOYEE_COMPENSATION_REQUEST =
  "DELETE_EMPLOYEE_COMPENSATION_REQUEST";
export const DELETE_EMPLOYEE_COMPENSATION_SUCCESS =
  "DELETE_EMPLOYEE_COMPENSATION_SUCCESS";
export const DELETE_EMPLOYEE_COMPENSATION_FAILURE =
  "DELETE_EMPLOYEE_COMPENSATION_FAILURE";

export const GET_EMPLOYEE_EMERGENCY_CONTACTS =
  "GET_EMPLOYEE_EMERGENCY_CONTACTS";
export const GET_EMPLOYEE_EMERGENCY_CONTACTS_REQUEST =
  "GET_EMPLOYEE_EMERGENCY_CONTACTS_REQUEST";
export const GET_EMPLOYEE_EMERGENCY_CONTACTS_SUCCESS =
  "GET_EMPLOYEE_EMERGENCY_CONTACTS_SUCCESS";
export const GET_EMPLOYEE_EMERGENCY_CONTACTS_FAILURE =
  "GET_EMPLOYEE_EMERGENCY_CONTACTS_FAILURE";

export const CREATE_EMPLOYEE_EMERGENCY_CONTACT =
  "CREATE_EMPLOYEE_EMERGENCY_CONTACT";
export const CREATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST =
  "CREATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST";
export const CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS =
  "CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS";
export const CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE =
  "CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE";

export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT =
  "UPDATE_EMPLOYEE_EMERGENCY_CONTACT";
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST =
  "UPDATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST";
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS =
  "UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS";
export const UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE =
  "UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE";

export const DELETE_EMPLOYEE_EMERGENCY_CONTACT =
  "DELETE_EMPLOYEE_EMERGENCY_CONTACT";
export const DELETE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST =
  "DELETE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST";
export const DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS =
  "DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS";
export const DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE =
  "DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE";

export const GET_EMPLOYEE_BENEFITS = "GET_EMPLOYEE_BENEFITS";
export const GET_EMPLOYEE_BENEFITS_REQUEST = "GET_EMPLOYEE_BENEFITS_REQUEST";
export const GET_EMPLOYEE_BENEFITS_SUCCESS = "GET_EMPLOYEE_BENEFITS_SUCCESS";
export const GET_EMPLOYEE_BENEFITS_FAILURE = "GET_EMPLOYEE_BENEFITS_FAILURE";

export const GET_ORG_EMPLOYEES = "GET_ORG_EMPLOYEES";
export const GET_ORG_EMPLOYEES_REQUEST = "GET_ORG_EMPLOYEES_REQUEST";
export const GET_ORG_EMPLOYEES_SUCCESS = "GET_ORG_EMPLOYEES_SUCCESS";
export const GET_ORG_EMPLOYEES_FAILURE = "GET_ORG_EMPLOYEES_FAILURE";

export const GET_EMPLOYEE_TIME_OFFS = "GET_EMPLOYEE_TIME_OFFS";
export const GET_EMPLOYEE_TIME_OFFS_REQUEST = "GET_EMPLOYEE_TIME_OFFS_REQUEST";
export const GET_EMPLOYEE_TIME_OFFS_SUCCESS = "GET_EMPLOYEE_TIME_OFFS_SUCCESS";
export const GET_EMPLOYEE_TIME_OFFS_FAILURE = "GET_EMPLOYEE_TIME_OFFS_FAILURE";

export const GET_EMPLOYEE_TIME_OFFS_HISTORIES =
  "GET_EMPLOYEE_TIME_OFFS_HISTORIES";
export const GET_EMPLOYEE_TIME_OFFS_HISTORIES_REQUEST =
  "GET_EMPLOYEE_TIME_OFFS_HISTORIES_REQUEST";
export const GET_EMPLOYEE_TIME_OFFS_HISTORIES_SUCCESS =
  "GET_EMPLOYEE_TIME_OFFS_HISTORIES_SUCCESS";
export const GET_EMPLOYEE_TIME_OFFS_HISTORIES_FAILURE =
  "GET_EMPLOYEE_TIME_OFFS_HISTORIES_FAILURE";

export const UPDATE_EMPLOYEE_TIME_OFF = "UPDATE_EMPLOYEE_TIME_OFF";
export const UPDATE_EMPLOYEE_TIME_OFF_REQUEST =
  "UPDATE_EMPLOYEE_TIME_OFF_REQUEST";
export const UPDATE_EMPLOYEE_TIME_OFF_SUCCESS =
  "UPDATE_EMPLOYEE_TIME_OFF_SUCCESS";
export const UPDATE_EMPLOYEE_TIME_OFF_FAILURE =
  "UPDATE_EMPLOYEE_TIME_OFF_FAILURE";

export const DELETE_EMPLOYEE_TIME_OFF = "DELETE_EMPLOYEE_TIME_OFF";
export const DELETE_EMPLOYEE_TIME_OFF_REQUEST =
  "DELETE_EMPLOYEE_TIME_OFF_REQUEST";
export const DELETE_EMPLOYEE_TIME_OFF_SUCCESS =
  "DELETE_EMPLOYEE_TIME_OFF_SUCCESS";
export const DELETE_EMPLOYEE_TIME_OFF_FAILURE =
  "DELETE_EMPLOYEE_TIME_OFF_FAILURE";

export const GET_EMPLOYEE_PAY_STUBS = "GET_EMPLOYEE_PAY_STUBS";
export const GET_EMPLOYEE_PAY_STUBS_REQUEST = "GET_EMPLOYEE_PAY_STUBS_REQUEST";
export const GET_EMPLOYEE_PAY_STUBS_SUCCESS = "GET_EMPLOYEE_PAY_STUBS_SUCCESS";
export const GET_EMPLOYEE_PAY_STUBS_FAILURE = "GET_EMPLOYEE_PAY_STUBS_FAILURE";

export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const UPLOAD_AVATAR_REQUEST = "UPLOAD_AVATAR_REQUEST";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_AVATAR_FAILURE = "UPLOAD_AVATAR_FAILURE";

export const CHANGE_EMPLOYEE_ROLE = "CHANGE_EMPLOYEE_ROLE";
export const CHANGE_EMPLOYEE_ROLE_REQUEST = "CHANGE_EMPLOYEE_ROLE_REQUEST";
export const CHANGE_EMPLOYEE_ROLE_SUCCESS = "CHANGE_EMPLOYEE_ROLE_SUCCESS";
export const CHANGE_EMPLOYEE_ROLE_FAILURE = "CHANGE_EMPLOYEE_ROLE_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CHANGE_STATUS_EMPLOYEE = "CHANGE_STATUS_EMPLOYEE";
export const CHANGE_STATUS_EMPLOYEE_REQUEST = "CHANGE_STATUS_EMPLOYEE_REQUEST";
export const CHANGE_STATUS_EMPLOYEE_SUCCESS = "CHANGE_STATUS_EMPLOYEE_SUCCESS";
export const CHANGE_STATUS_EMPLOYEE_FAILURE = "CHANGE_STATUS_EMPLOYEE_FAILURE";