import {
  call,
  put,
  takeLatest,
  fork,
  all,
  delay,
  select,
} from "redux-saga/effects";
import * as Types from "../../data/types/AuthType";
import * as Func from "../../utils/functions";
import history from "../../utils/history";
import * as Auth from "../actions/authAction";
import {
  CONFIG_LOCAL_STORAGE,
  PATHS,
  REFRESH_TOKEN_DELAY_TIMEOUT,
} from "../../constants/define";
import * as LocalStorage from "../../utils/localStorage";
import { message } from "antd";
import AuthRequest from "../mapping/Request/AuthRequest";
import TAG_DEFINE from "../../constants/common";
import * as authService from "../services/authService";
import { notification } from "antd";

function* redirectTo(location) {
  yield history.push(location);
}

function* signinSaga() {
  yield takeLatest(
    Types.SIGNIN_REQUEST,
    Func.sagaWrapper(function* (action) {
      const body = action.params;
      const data = yield call(authService.signin, body.email, body.password);
      if (data?.data?.status.toUpperCase() === "SUCCESS") {
        yield put({ type: Types.SIGNIN_SUCCESS });
        LocalStorage.set(
          CONFIG_LOCAL_STORAGE.ACCESS_TOKEN,
          data?.data?.data.result
        );
        // LocalStorage.set(
        //   CONFIG_LOCAL_STORAGE.REFRESH_TOKEN,
        //   data?.data?.data?.result.
        // );
        yield put({ type: Types.CHECK_USER_REQUEST });
        yield call(redirectTo, PATHS.ROOT.path);
      } else {
        yield errorHandleAuth(Types.SIGNIN_FAILURE, data.data);
      }
    }, errorHandle(Types.SIGNIN_FAILURE))
  );
}

function* signoutSaga() {
  yield takeLatest(
    Types.SIGNOUT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = yield select();
      // if (data?.authReducer?.logged) {
      //   yield call(authServiceTest.signout);
      // }
      yield put({ type: Types.SIGNOUT_SUCCESS });
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
      history.go(PATHS.LOGIN.path);
    }, errorHandle(Types.SIGNOUT_FAILURE))
  );
}

function* checkEmployeeSaga() {
  yield takeLatest(
    Types.CHECK_USER_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = yield call(authService.getUserProfile);
      // LocalStorage.set(CONFIG_LOCAL_STORAGE.PROFILE, data);
      yield put(Auth.checkUserSuccess(data));
    }, errorHandleProfile(Types.CHECK_USER_FAILURE))
  );
}

function* refreshTokenFunc(action, shouldGetProfile = true) {
  const refreshToken =
    action.refreshToken || LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
  const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
  const shouldRefresh =
    parseInt(new Date().getTime()) >=
    parseInt(new Date(expires).getTime()) - REFRESH_TOKEN_DELAY_TIMEOUT;
  let intervalLoaded = false;

  function* refreshTokenInterval() {
    if (shouldGetProfile) {
      yield put({ type: Types.CHECK_USER_REQUEST });
    }
    if (!intervalLoaded) {
      const expireDate = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
      const delayTimeout =
        parseInt(new Date(expireDate).getTime()) -
        parseInt(new Date().getTime()) -
        REFRESH_TOKEN_DELAY_TIMEOUT;
      yield delay(delayTimeout);
      yield call(
        refreshTokenFunc,
        { refreshToken: LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN) },
        false
      );
      intervalLoaded = true;
    }
  }

  if (shouldRefresh && refreshToken) {
    const data = yield call(authService.refreshToken, refreshToken);
    if (data.accessToken) {
      yield put({ type: Types.REFRESH_TOKEN_SUCCESS });
      LocalStorage.set(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN, data.accessToken);
      LocalStorage.set(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN, data.refreshToken);
      LocalStorage.set(CONFIG_LOCAL_STORAGE.EXPIRES, data.expires);
      yield refreshTokenInterval();
    }
  } else {
    yield refreshTokenInterval();
  }
}

function* refreshTokenSaga() {
  yield takeLatest(
    Types.REFRESH_TOKEN_REQUEST,
    Func.sagaWrapper(refreshTokenFunc, errorHandleRefresh(Types.SIGNIN_FAILURE))
  );
}

function* updateProfile() {
  yield takeLatest(
    Types.UPDATE_PROFILE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const data = new AuthRequest(action.params).exportUpdate();
      yield call(authService.update, data);
      message.success(TAG_DEFINE.VALIDATION.statusCode.code["204"]);
      // yield put({ type: Types.HOTEL_GET_ITEM_ACTION });
      // yield put({ type: Types.VOUCHER_FAIL });
      yield put(Auth.updateProfileSuccess());
      yield put(Auth.checkUserRequest());
      // yield put(VoucherActions.listVoucherAction(action.params.filters || {}));
    }, errorHandle(Types.SIGNIN_FAILURE))
  );
}

function* errorHandleAuth(errorActionType, data) {
  if (data.status.toUpperCase() === "FAIL") {
    message.error(data.errors.message);
    yield put({ type: errorActionType });
    return false;
  } else if (data.status.toUpperCase() === "VALIDATION") {
    message.error("Lỗi validation");
    yield put({ type: errorActionType });
    return false;
  }
  message.error("Lỗi hệ thống");
  console.warn(data);
  yield put({ type: errorActionType });
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType });
  });
}

function errorHandleProfile(errorActionType) {
  return function* (e) {
    if (e?.status === 401 || e?.errors[0]?.code === 401) {
      yield put({ type: Types.SIGNOUT_REQUEST });
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
      LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
      history.go(PATHS.LOGIN.path);
    } else {
      console.warn(e);
      yield put({ type: errorActionType });
    }
  };
}

function errorHandleRefresh(errorActionType) {
  return function* (e) {};
}

export function* authSaga() {
  yield all([
    fork(signinSaga),
    fork(signoutSaga),
    fork(checkEmployeeSaga),
    fork(refreshTokenSaga),
    fork(updateProfile),
  ]);
}
