import * as Types from "../types/FileType";

export const getFilesRequest = (filters) => ({
  type: Types.GET_FILES_REQUEST,
  filters,
});
export const getFilesSuccess = (result) => ({
  type: Types.GET_FILES_SUCCESS,
  result,
});

export const uploadFileRequest = (payload) => ({
  type: Types.UPLOAD_FILE_REQUEST,
  payload,
});
export const uploadFileSuccess = (result) => ({
  type: Types.UPLOAD_FILE_SUCCESS,
  result,
});

export const deleteFileRequest = (params) => ({
  type: Types.DELETE_FILE_REQUEST,
  params,
});

export const deleteFileSuccess = (result) => ({
  type: Types.DELETE_FILE_SUCCESS,
  result,
});

export const getFoldersRequest = (filter) => ({
  type: Types.GET_FOLDERS_REQUEST,
  filter,
});

export const getFoldersSuccess = (result) => ({
  type: Types.GET_FOLDERS_SUCCESS,
  result,
});

export const createFolderRequest = (payload) => ({
  type: Types.CREATE_FOLDER_REQUEST,
  payload,
});

export const createFolderSuccess = (result) => ({
  type: Types.CREATE_FOLDER_SUCCESS,
  result,
});

export const updateFolderRequest = (payload) => ({
  type: Types.UPDATE_FOLDER_REQUEST,
  payload,
});

export const updateFolderSuccess = (result) => ({
  type: Types.UPDATE_FOLDER_SUCCESS,
  result,
});

export const deleteFolderRequest = (params) => ({
  type: Types.DELETE_FOLDER_REQUEST,
  params,
});

export const deleteFolderSuccess = (result) => ({
  type: Types.DELETE_FOLDER_SUCCESS,
  result,
});
