import * as Types from "../types/PayScheduleType";

export const getPaySchedulesRequest = (filter) => ({
  type: Types.GET_PAY_SCHEDULES_REQUEST,
  filter,
});

export const getPaySchedulesSuccess = (result) => ({
  type: Types.GET_PAY_SCHEDULES_SUCCESS,
  result,
});

export const createPayScheduleRequest = (payload) => ({
  type: Types.CREATE_PAY_SCHEDULE_REQUEST,
  payload,
});

export const createPayScheduleSuccess = (result) => ({
  type: Types.CREATE_PAY_SCHEDULE_SUCCESS,
  result,
});

export const updatePayScheduleRequest = (payload) => ({
  type: Types.UPDATE_PAY_SCHEDULE_REQUEST,
  payload,
});

export const updatePayScheduleSuccess = (result) => ({
  type: Types.UPDATE_PAY_SCHEDULE_SUCCESS,
  result,
});

export const deletePayScheduleRequest = (params) => ({
  type: Types.DELETE_PAY_SCHEDULE_REQUEST,
  params,
});

export const deletePayScheduleSuccess = (result) => ({
  type: Types.DELETE_PAY_SCHEDULE_SUCCESS,
  result,
});
