import * as Types from "../types/TimeOffType";

export const getTimeOffCategoriesRequest = (filter) => ({
  type: Types.GET_TIME_OFF_CATEGORIES_REQUEST,
  filter,
});
export const createTimeOffCategoryRequest = (payload) => ({
  type: Types.CREATE_TIME_OFF_CATEGORY_REQUEST,
  payload,
});
export const updateTimeOffCategoryRequest = (payload) => ({
  type: Types.UPDATE_TIME_OFF_CATEGORY_REQUEST,
  payload,
});
export const deleteTimeOffCategoryRequest = (params) => ({
  type: Types.DELETE_TIME_OFF_CATEGORY_REQUEST,
  params,
});
export const sendTimeOffRequest = (payload) => ({
  type: Types.SEND_TIME_OFF_REQUEST,
  payload,
});

export const createTimeOffCategorySuccess = (result) => ({
  type: Types.CREATE_TIME_OFF_CATEGORY_SUCCESS,
  result,
});
export const getTimeOffCategoriesSuccess = (result) => ({
  type: Types.GET_TIME_OFF_CATEGORIES_SUCCESS,
  result,
});
export const updateTimeOffCategorySuccess = (result) => ({
  type: Types.UPDATE_TIME_OFF_CATEGORY_SUCCESS,
  result,
});
export const deleteTimeOffCategorySuccess = (result) => ({
  type: Types.DELETE_TIME_OFF_CATEGORY_SUCCESS,
  result,
});
export const requestTimeOffSuccess = (result) => ({
  type: Types.SEND_TIME_OFF_SUCCESS,
  result,
});

export const getPeopleOnLeaveDaysRequest = (params) => ({
  type: Types.GET_PEOPLE_ON_LEAVE_DAYS_REQUEST,
  params,
});

export const getPeopleOnLeaveDaysSuccess = (result) => ({
  type: Types.GET_PEOPLE_ON_LEAVE_DAYS_SUCCESS,
  result,
});

export const getEmployeeTimeOffsHistoriesRequest = (params) => ({
  type: Types.GET_EMPLOYEE_TIME_OFF_HISTORIES_REQUEST,
  params,
});

export const getEmployeeTimeOffsHistoriesSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_TIME_OFF_HISTORIES_SUCCESS,
  result,
});
