export const GET_BENEFIT_PLANS = "GET_BENEFIT_PLANS";
export const GET_BENEFIT_PLANS_REQUEST = "GET_BENEFIT_PLANS_REQUEST";
export const GET_BENEFIT_PLANS_SUCCESS = "GET_BENEFIT_PLANS_SUCCESS";
export const GET_BENEFIT_PLANS_FAILURE = "GET_BENEFIT_PLANS_FAILURE";

export const CREATE_BENEFIT_PLAN = "CREATE_BENEFIT_PLAN";
export const CREATE_BENEFIT_PLAN_REQUEST = "CREATE_BENEFIT_PLAN_REQUEST";
export const CREATE_BENEFIT_PLAN_SUCCESS = "CREATE_BENEFIT_PLAN_SUCCESS";
export const CREATE_BENEFIT_PLAN_FAILURE = "CREATE_BENEFIT_PLAN_FAILURE";

export const UPDATE_BENEFIT_PLAN = "UPDATE_BENEFIT_PLAN";
export const UPDATE_BENEFIT_PLAN_REQUEST = "UPDATE_BENEFIT_PLAN_REQUEST";
export const UPDATE_BENEFIT_PLAN_SUCCESS = "UPDATE_BENEFIT_PLAN_SUCCESS";
export const UPDATE_BENEFIT_PLAN_FAILURE = "UPDATE_BENEFIT_PLAN_FAILURE";

export const DELETE_BENEFIT_PLAN = "DELETE_BENEFIT_PLAN";
export const DELETE_BENEFIT_PLAN_REQUEST = "DELETE_BENEFIT_PLAN_REQUEST";
export const DELETE_BENEFIT_PLAN_SUCCESS = "DELETE_BENEFIT_PLAN_SUCCESS";
export const DELETE_BENEFIT_PLAN_FAILURE = "DELETE_BENEFIT_PLAN_FAILURE";

export const GET_BENEFIT_CATEGORIES = "GET_BENEFIT_CATEGORIES";
export const GET_BENEFIT_CATEGORIES_REQUEST = "GET_BENEFIT_CATEGORIES_REQUEST";
export const GET_BENEFIT_CATEGORIES_SUCCESS = "GET_BENEFIT_CATEGORIES_SUCCESS";
export const GET_BENEFIT_CATEGORIES_FAILURE = "GET_BENEFIT_CATEGORIES_FAILURE";

export const CREATE_BENEFIT_CATEGORY = "CREATE_BENEFIT_CATEGORY";
export const CREATE_BENEFIT_CATEGORY_REQUEST =
  "CREATE_BENEFIT_CATEGORY_REQUEST";
export const CREATE_BENEFIT_CATEGORY_SUCCESS =
  "CREATE_BENEFIT_CATEGORY_SUCCESS";
export const CREATE_BENEFIT_CATEGORY_FAILURE =
  "CREATE_BENEFIT_CATEGORY_FAILURE";

export const UPDATE_BENEFIT_CATEGORY = "UPDATE_BENEFIT_CATEGORY";
export const UPDATE_BENEFIT_CATEGORY_REQUEST =
  "UPDATE_BENEFIT_CATEGORY_REQUEST";
export const UPDATE_BENEFIT_CATEGORY_SUCCESS =
  "UPDATE_BENEFIT_CATEGORY_SUCCESS";
export const UPDATE_BENEFIT_CATEGORY_FAILURE =
  "UPDATE_BENEFIT_CATEGORY_FAILURE";

export const DELETE_BENEFIT_CATEGORY = "DELETE_BENEFIT_CATEGORY";
export const DELETE_BENEFIT_CATEGORY_REQUEST =
  "DELETE_BENEFIT_CATEGORY_REQUEST";
export const DELETE_BENEFIT_CATEGORY_SUCCESS =
  "DELETE_BENEFIT_CATEGORY_SUCCESS";
export const DELETE_BENEFIT_CATEGORY_FAILURE =
  "DELETE_BENEFIT_CATEGORY_FAILURE";
