import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function verifyRoute(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.VERIFY_ROUTE,
    params: params,
    parser: (data) => data,
  }).get();
}
