import * as Types from "../types/PayScheduleType";

const initialState = {
  paySchedules: [],
  errors: [],
};

const payScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_PAY_SCHEDULES_SUCCESS: {
      const paySchedules = action?.result;
      return {
        ...state,
        paySchedules: paySchedules,
      };
    }
    case Types.CREATE_PAY_SCHEDULE_SUCCESS: {
      const paySchedules = [action?.result, ...state?.paySchedules];
      return {
        ...state,
        paySchedules: paySchedules,
      };
    }
    case Types.UPDATE_PAY_SCHEDULE_SUCCESS: {
      const paySchedules = state?.paySchedules?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return {
        ...state,
        paySchedules: paySchedules,
      };
    }
    case Types.DELETE_PAY_SCHEDULE_SUCCESS:
      const paySchedules = state?.paySchedules.filter(
        (item) => item?.id !== action?.result?.id
      );
      return {
        ...state,
        paySchedules: paySchedules,
      };
    default:
      return state;
  }
};

export default payScheduleReducer;
