import * as Types from "../types/CompanyTypes";

const initialState = {
  companies: [],
  company: {},
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_COMPANIES_SUCCESS: {
      const companies = action?.result || [];
      return {
        ...state,
        companies,
      };
    }
    case Types.GET_COMPANY_SUCCESS: {
      const company = action?.result || {};
      return {
        ...state,
        company,
      };
    }
    case Types.CHANGE_STATUS_COMPANY_SUCCESS: {
      const companies = state?.companies?.map((company) => {
        if (company?.id === action?.result?.id) {
          return action?.result;
        }
        return company;
      });
      return {
        ...state,
        companies,
      };
    }
    case Types.CREATE_COMPANY_SUCCESS: {
      const companies = [action?.result, ...state?.companies];
      return {
        ...state,
        companies,
      };
    }
    case Types.UPDATE_COMPANY_SUCCESS: {
      const company = { ...state?.company, ...action?.result };
      return {
        ...state,
        company,
      };
    }
    case Types.DELETE_COMPANY_SUCCESS: {
      const companies = state?.companies?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return { ...state, companies };
    }
    case Types.UPLOAD_COMPANY_LOGO_SUCCESS: {
      return state;
    }
    default:
      return state;
  }
};
export default companyReducer;
