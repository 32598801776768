import * as Types from "../types/ApprovalType";

export const getApprovalsRequest = (filter) => ({
  type: Types.GET_APPROVALS_REQUEST,
  filter,
});

export const getApprovalsSuccess = (result) => ({
  type: Types.GET_APPROVALS_SUCCESS,
  result,
});

export const grantApprovalRequest = (payload) => ({
  type: Types.GRANT_APPROVAL_REQUEST,
  payload,
});

export const grantApprovalSuccess = (result) => ({
  type: Types.GRANT_APPROVAL_SUCCESS,
  result,
});

export const getApprovalRequest = (filter) => ({
  type: Types.GET_APPROVAL_REQUEST,
  filter,
});

export const getApprovalSuccess = (result) => ({
  type: Types.GET_APPROVAL_SUCCESS,
  result,
});

export const getApprovalHistoriesRequest = (filter) => ({
  type: Types.GET_APPROVAL_HISTORIES_REQUEST,
  filter,
});

export const getApprovalHistoriesSuccess = (result) => ({
  type: Types.GET_APPROVAL_HISTORIES_SUCCESS,
  result,
});
