export const PAY_PERIOD_OPTIONS = {
  WEEKLY: {
    key: "WEEKLY",
    displayFrequencyName: "Weekly",
  },
  SEMI_MONTHLY: {
    key: "SEMI_MONTHLY",
    displayFrequencyName: "Twice a month",
  },
  MONTHLY: {
    key: "MONTHLY",
    displayFrequencyName: "Monthly",
  },
};

export const PAY_RULES = {
  BEFORE: "BEFORE",
  AFTER: "AFTER",
};
