import * as Types from "../types/DepartmentTypes";

const initialState = {
  list: [],
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_DEPARTMENTS_SUCCESS: {
      const list = action?.result || [];
      return {
        ...state,
        list,
      };
    }
    case Types.CREATE_DEPARTMENT_SUCCESS: {
      const list = [action?.result, ...state?.list];
      return {
        ...state,
        list,
      };
    }
    case Types.UPDATE_DEPARTMENT_SUCCESS: {
      const list = state?.list?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return {
        ...state,
        list,
      };
    }
    case Types.DELETE_DEPARTMENT_SUCCESS: {
      const list = state?.list?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return { ...state, list };
    }
    default:
      return state;
  }
};
export default departmentReducer;
