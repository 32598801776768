import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getTimeOffCategories() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.TIME_OFF_GET_CATEGORIES,
    parser: (data) => data.data.result,
  }).get();
}

export function createTimeOffCategory(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.TIME_OFF_ADD_CATEGORY,
    parser: (data) => data.data.result,
  }).post(payload);
}

export function updateTimeOffCategory(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.TIME_OFF_EDIT_CATEGORY,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).put(payload);
}

export function deleteTimeOffCategory(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.TIME_OFF_EDIT_CATEGORY,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function sendTimeOff(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.TIME_OFF_REQUEST,
    parser: (data) => data.data.result,
  }).post(payload);
}

export function getPeopleOnLeaveDays(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_PEOPLE_ON_LEAVE_DAYS,
    params: params,
    parser: (data) => data.data.result,
  }).get();
}

export function getEmployeeTimeOffsHistories(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EMPLOYEE_TIME_OFFS_HISTORIES,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).get();
}
