import { pick } from "lodash";
export default class User {
  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    const {
      id,
      first_name,
      last_name,
      full_name,
      gender,
      job_title,
      manager_id,
      is_super_admin,
      address,
      role,
    } = data?.employee || {};
    this.id = data?.id || undefined;

    this.user = {
      firstName: first_name || "",
      lastName: last_name || "",
      fullName: full_name || `${first_name || "U"} ${last_name || "K"}`,
      gender: !!gender,
      jobTitle: job_title || "",
      managerId: manager_id || null,
      isSuperAdmin: !!is_super_admin || false,
      address: address || "",
      isAdmin: !!role?.find((item) => item?.name === "Administrator"),
      isManager: !!role?.find((item) => item?.name === "Manager"),
      avatar: `https://ui-avatars.com/api/?bold=true&color=ffffff&background=015a8f&name=${
        first_name || "U"
      } ${last_name || "K"}`,
      ...data?.employee,
    };
    this.menu = this.setItemMenu(data?.menu);
    this.company = data?.company;
  }

  setItemMenu(items = []) {
    let newMenu = [];
    if ((items || []).length > 0) {
      items.map((i) =>
        newMenu.push({
          name: i?.name?.toLowerCase() || "",
          items: this.setItemRoute(i?.items || []),
        })
      );
    }
    return newMenu;
  }

  setItemRoute(items = []) {
    let newRoute = [];
    if ((items || []).length > 0) {
      items.map((i) =>
        newRoute.push({
          name: i?.name || "",
          route: i?.route || "",
        })
      );
    }
    return newRoute;
  }

  export() {
    return {
      id: this.id,
      avatarUrl: this.avatarUrl,
      password: this.password,
      deactivated: this.deactivated,
      propertyIds: this.propertyIds,
      permissions: this.permissions,
      roleId: this.roleId,
      roleName: this.roleName,
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      username: this.username,
      phoneNumber: this.phoneNumber,
      avatar: this.avatar,
    };
  }
}
