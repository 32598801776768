import { pick } from "lodash";

export default class CustomerRequest {
  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    this.id = data?.id || "";
    this.first_name = data?.firstName || "";
    this.last_name = data?.lastName || "";
    this.email = data?.email || "";
    this.password = data?.password || "";
    this.phone_number =
      `+${data?.phoneNumber?.code} ${data?.phoneNumber?.phone}` || "";
    this.country = data?.phoneNumber?.short || "";
    this.company_id = data?.companyId || "";
    this.status = data?.status;
    this.avatar = data?.avatar || "";
    this.date_of_birth = data?.dateOfBirth?.valueOf()?.toString() || "";
    this.gender = data?.gender === "Male" ? 1 : 2;
    this.country = data?.country || "";
    this.address = data?.address || "";
    this.roles = data?.roles;
  }

  exportCreate() {
    return pick(this, [
      "id",
      "first_name",
      "last_name",
      "email",
      "password",
      "phone_number",
      "country",
      "companyId",
      "status",
      "avatar",
      "date_of_birth",
      "gender",
      "country",
      "address",
      "roles",
    ]);
  }

  exportUpdate() {
    return pick(this, [
      "id",
      "first_name",
      "last_name",
      "email",
      "phone_number",
      "country",
      "status",
      "avatar",
      "date_of_birth",
      "gender",
      "country",
      "address",
      "roles",
    ]);
  }
}
