import { combineReducers } from "redux";
import authReducer from "./authReducer";
import verifyReducer from "./verifyReducer";
import employeeReducer from "./employeeReducer";
import benefitReducer from "./benefitReducer";
import fileReducer from "./fileReducer";
import timeOffReducer from "./timeOffReducer";
import approvalReducer from "./approvalReducer";
import payScheduleReducer from "./payScheduleReducer";
import payRollReducer from "./payRollReducer";
import loadingReducer from "./loadingReducer";
import companyReducer from "./companyReducer";
import adminAccountReducer from "./adminAccountReducer";
import customerReducer from "./customerReducer";
import departmentReducer from "./departmentReducer";

export default combineReducers({
  authReducer,
  verifyReducer,
  employeeReducer,
  benefitReducer,
  fileReducer,
  timeOffReducer,
  approvalReducer,
  payScheduleReducer,
  payRollReducer,
  loadingReducer,
  companyReducer,
  adminAccountReducer,
  customerReducer,
  departmentReducer,
});
