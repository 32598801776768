import React, { Component, Fragment, Suspense } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  checkUserRequest,
  refreshTokenRequest,
} from "../data/actions/authAction";
import { verifyInit, verifyRouteAction } from "../data/actions/verifyAction";
import { signoutRequest } from "../data/actions/authAction";
import { CONFIG_LOCAL_STORAGE } from "../constants/define";
import Navigation from "./common/layout/Navigation";
import PERMISSION from "../constants/permission";
import * as LocalStorage from "../utils/localStorage";
import { find } from "lodash";
import { Spin } from "antd";
import routers from "../routers";
import { createLoadingSelector } from "../data/reselects/loadingSelector";
import * as Types from "../data/types/AuthType";

class ContainerIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataUser: {},
    };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      let group = location?.state?.group || this.props?.group || "",
        withParams = location?.state?.withParams || "";
      if (
        group &&
        (group !== "errorPages" || this.props?.group !== "errorPages")
      ) {
        let pathname = location.pathname;
        if (withParams) {
          let arrayPath = location.pathname.split("/");
          arrayPath[arrayPath.length - 1] = ":id";
          pathname = arrayPath.join("/");
        }
        let permission = find(PERMISSION[group.toUpperCase()], {
          route: pathname,
        });
        if (permission) {
          this.props.onVerifyRoute({
            route: `${group}.${permission.permission}`,
          });
        }
      }
    });
  }

  componentDidMount() {
    const { location } = this.props;
    let group = location?.state?.group || this.props?.group || "",
      withParams = location?.state?.withParams || this.props?.withParams || "";

    if (group) {
      let pathname = location.pathname;
      if (withParams) {
        let arrayPath = location.pathname.split("/");
        arrayPath[arrayPath.length - 1] = ":id";
        pathname = arrayPath.join("/");
      }
      let permission = find(PERMISSION[group.toUpperCase()], {
        route: pathname,
      });
      if (permission) {
        this.props.onVerifyRoute({
          route: `${group}.${permission.permission}`,
        });
      }
      console.log("VERSION:", process.env.REACT_APP_VERSION);
      // this.props.onCheckUser();

      // const profile = LocalStorage.get(CONFIG_LOCAL_STORAGE.PROFILE);
      const token = LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
      // const refreshToken = LocalStorage.get(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
      // const expires = LocalStorage.get(CONFIG_LOCAL_STORAGE.EXPIRES);
      //TODO: Ad-hoc fix, recheck
      if (!token) {
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.PROFILE);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.EXPIRES);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.REFRESH_TOKEN);
        LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
      } else this.props.onCheckUser();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { authLogin, location, verifyAction, isFetching } = this.props;
    const group = this.location?.state?.group || this.props?.group || "";
    const path = this.props?.path || "";
    const getMenu = find(routers, {
      group: location?.state?.group || group || "",
    });

    return (
      <>
        {isFetching ? (
          <div className='spinning' style={{ height: "100vh" }}>
            <Spin tip='Loading...' />
          </div>
        ) : (
          <div className='site'>
            <Navigation
              path={location.pathname}
              menu={authLogin?.user?.menu || []}
              profile={authLogin.user || {}}
              group={this.location?.state?.group || this.props?.group || ""}
            />
            <div className='content'>
              <header className='content__header'>
                <div className='block'>
                  <button
                    className='btn btn-primary'
                    type='button'
                    data-bs-toggle='offcanvas'
                    data-bs-target='#demo'>
                    <i className='fa fa-bars' aria-hidden='true'></i>
                  </button>
                  <nav>
                    <ol className='breadcrumb'>
                      <li className='breadcrumb--item'>{getMenu?.title}</li>
                      <li
                        className='breadcrumb--item active'
                        aria-current='page'>
                        {find(getMenu?.sub_menu, { path: path })?.title}
                      </li>
                    </ol>
                  </nav>
                </div>
              </header>
              <div className='content__body'>
                <Suspense
                  fallback={
                    <div className='spinning'>
                      <Spin tip='loading...' />
                    </div>
                  }>
                  {React.createElement(
                    this.props.children.type,
                    {
                      list_permission: PERMISSION,
                      group: group,
                      path: path,
                      ...this.props.children.props,
                    },
                    this.props.children
                  )}
                </Suspense>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  Types.CHECK_USER,
  Types?.REFRESH_TOKEN,
  Types.SIGNOUT,
]);
const mapStateToProps = (state) => ({
  isFetching: loadingSelector(state),
  authLogin: state.authReducer,
  verifyAction: state.verifyReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckUser: () => {
      dispatch(checkUserRequest());
    },
    onLogoutRequest: () => {
      dispatch(signoutRequest());
    },
    onVerifyRoute: (params) => {
      dispatch(verifyRouteAction(params));
    },
    onVerifyInit: (params) => {
      dispatch(verifyInit(params));
    },
    refreshToken: (token) => dispatch(refreshTokenRequest(token)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContainerIndex)
);
