import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/FileType";
import * as Func from "../../utils/functions";

import * as FunctionAction from "../actions/fileAction";
import * as FunctionService from "../services/fileService";
import { notification } from "antd";

function* getFiles() {
  yield takeLatest(
    Types.GET_FILES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getFiles);
      yield put(FunctionAction.getFilesSuccess(result));
    }, errorHandle(Types.GET_FILES_FAILURE))
  );
}

function* deleteFile() {
  yield takeLatest(
    Types.DELETE_FILE_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteFile, action?.params);
      yield put(FunctionAction.deleteFileSuccess(action?.params));
      yield put(FunctionAction?.getFilesRequest());
      notification.success({ message: "Delete Successfully" });
    }, errorHandle(Types.DELETE_FILE_FAILURE))
  );
}

function* uploadFile() {
  yield takeLatest(
    Types.UPLOAD_FILE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const { data, onSuccess, onProgress } = action?.payload;
      const result = yield call(FunctionService.uploadFile, {
        data,
        onProgress,
      });
      yield put(
        FunctionAction.uploadFileSuccess({
          result,
          onSuccess,
        })
      );
      // yield put(FunctionAction.getFilesRequest());
      notification.success({ message: "Upload Successfully" });
    }, errorHandle(Types.UPLOAD_FILE_FAILURE))
  );
}

function* getFolders() {
  yield takeLatest(
    Types.GET_FOLDERS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getFolders);
      yield put(FunctionAction.getFoldersSuccess(result));
    }, errorHandle(Types.GET_FOLDERS_FAILURE))
  );
}

function* createFolder() {
  yield takeLatest(
    Types.CREATE_FOLDER_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.createFolder, action?.payload);
      yield put(FunctionAction.createFolderSuccess(result));
      notification.success({ message: "Add Success" });
    }, errorHandle(Types.CREATE_FOLDER_FAILURE))
  );
}

function* updateFolder() {
  yield takeLatest(
    Types.UPDATE_FOLDER_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.updateFolder, action?.payload);
      yield put(FunctionAction.updateFolderSuccess(result));
      notification.success({ message: "Rename Success" });
    }, errorHandle(Types.UPDATE_FOLDER_FAILURE))
  );
}

function* actionDeleteFolder() {
  yield takeLatest(
    Types.DELETE_FOLDER_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteFolder, action?.params);
      yield put(FunctionAction.deleteFolderSuccess(action?.params));
      notification.success({ message: "Delete Success" });
    }, errorHandle(Types.DELETE_FOLDER_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* fileSaga() {
  yield all([
    fork(getFolders),
    fork(getFiles),
    fork(deleteFile),
    fork(createFolder),
    fork(updateFolder),
    fork(actionDeleteFolder),
    fork(uploadFile),
  ]);
}
