export const GET_EMPLOYEES_ON_PAY_ROLL = "GET_EMPLOYEES_ON_PAY_ROLL";
export const GET_EMPLOYEES_ON_PAY_ROLL_REQUEST =
  "GET_EMPLOYEES_ON_PAY_ROLL_REQUEST";
export const GET_EMPLOYEES_ON_PAY_ROLL_SUCCESS =
  "GET_EMPLOYEES_ON_PAY_ROLL_SUCCESS";
export const GET_EMPLOYEES_ON_PAY_ROLL_FAILURE =
  "GET_EMPLOYEES_ON_PAY_ROLL_FAILURE";

export const GET_DRAFT_PAY_RUNS = "GET_DRAFT_PAY_RUNS";
export const GET_DRAFT_PAY_RUNS_REQUEST = "GET_DRAFT_PAY_RUNS_REQUEST";
export const GET_DRAFT_PAY_RUNS_SUCCESS = "GET_DRAFT_PAY_RUNS_SUCCESS";
export const GET_DRAFT_PAY_RUNS_FAILURE = "GET_DRAFT_PAY_RUNS_FAILURE";

export const GET_APPROVED_PAY_RUNS = "GET_APPROVED_PAY_RUNS";
export const GET_APPROVED_PAY_RUNS_REQUEST = "GET_APPROVED_PAY_RUNS_REQUEST";
export const GET_APPROVED_PAY_RUNS_SUCCESS = "GET_APPROVED_PAY_RUNS_SUCCESS";
export const GET_APPROVED_PAY_RUNS_FAILURE = "GET_APPROVED_PAY_RUNS_FAILURE";

export const GET_PAID_PAY_RUNS = "GET_PAID_PAY_RUNS";
export const GET_PAID_PAY_RUNS_REQUEST = "GET_PAID_PAY_RUNS_REQUEST";
export const GET_PAID_PAY_RUNS_SUCCESS = "GET_PAID_PAY_RUNS_SUCCESS";
export const GET_PAID_PAY_RUNS_FAILURE = "GET_PAID_PAY_RUNS_FAILURE";

export const APPROVE_DRAFT_PAY_RUN = "APPROVE_DRAFT_PAY_RUN";
export const APPROVE_DRAFT_PAY_RUN_REQUEST = "APPROVE_DRAFT_PAY_RUN_REQUEST";
export const APPROVE_DRAFT_PAY_RUN_SUCCESS = "APPROVE_DRAFT_PAY_RUN_SUCCESS";
export const APPROVE_DRAFT_PAY_RUN_FAILURE = "APPROVE_DRAFT_PAY_RUN_FAILURE";

export const REVERT_APPROVED_PAY_RUN = "REVERT_APPROVED_PAY_RUN";
export const REVERT_APPROVED_PAY_RUN_REQUEST =
  "REVERT_APPROVED_PAY_RUN_REQUEST";
export const REVERT_APPROVED_PAY_RUN_SUCCESS =
  "REVERT_APPROVED_PAY_RUN_SUCCESS";
export const REVERT_APPROVED_PAY_RUN_FAILURE =
  "REVERT_APPROVED_PAY_RUN_FAILURE";

export const ADD_EMPLOYEE_ON_PAY_ROLL = "ADD_EMPLOYEE_ON_PAY_ROLL";
export const ADD_EMPLOYEE_ON_PAY_ROLL_REQUEST =
  "ADD_EMPLOYEE_ON_PAY_ROLL_REQUEST";
export const ADD_EMPLOYEE_ON_PAY_ROLL_SUCCESS =
  "ADD_EMPLOYEE_ON_PAY_ROLL_SUCCESS";
export const ADD_EMPLOYEE_ON_PAY_ROLL_FAILURE =
  "ADD_EMPLOYEE_ON_PAY_ROLL_FAILURE";

export const REMOVE_EMPLOYEE_ON_PAY_ROLL = "REMOVE_EMPLOYEE_ON_PAY_ROLL";
export const REMOVE_EMPLOYEE_ON_PAY_ROLL_REQUEST =
  "REMOVE_EMPLOYEE_ON_PAY_ROLL_REQUEST";
export const REMOVE_EMPLOYEE_ON_PAY_ROLL_SUCCESS =
  "REMOVE_EMPLOYEE_ON_PAY_ROLL_SUCCESS";
export const REMOVE_EMPLOYEE_ON_PAY_ROLL_FAILURE =
  "REMOVE_EMPLOYEE_ON_PAY_ROLL_FAILURE";

export const DELETE_PAY_RUN = "DELETE_PAY_RUN";
export const DELETE_PAY_RUN_REQUEST = "DELETE_PAY_RUN_REQUEST";
export const DELETE_PAY_RUN_SUCCESS = "DELETE_PAY_RUN_SUCCESS";
export const DELETE_PAY_RUN_FAILURE = "DELETE_PAY_RUN_FAILURE";

export const UPDATE_PAY_RUN = "UPDATE_PAY_RUN";
export const UPDATE_PAY_RUN_REQUEST = "UPDATE_PAY_RUN_REQUEST";
export const UPDATE_PAY_RUN_SUCCESS = "UPDATE_PAY_RUN_SUCCESS";
export const UPDATE_PAY_RUN_FAILURE = "UPDATE_PAY_RUN_FAILURE";

export const UPDATE_EMPLOYEE_IN_PAY_RUN = "UPDATE_EMPLOYEE_IN_PAY_RUN";
export const UPDATE_EMPLOYEE_IN_PAY_RUN_REQUEST =
  "UPDATE_EMPLOYEE_IN_PAY_RUN_REQUEST";
export const UPDATE_EMPLOYEE_IN_PAY_RUN_SUCCESS =
  "UPDATE_EMPLOYEE_IN_PAY_RUN_SUCCESS";
export const UPDATE_EMPLOYEE_IN_PAY_RUN_FAILURE =
  "UPDATE_EMPLOYEE_IN_PAY_RUN_FAILURE";

export const ADD_EMPLOYEES_IN_PAY_RUN = "ADD_EMPLOYEES_IN_PAY_RUN";
export const ADD_EMPLOYEES_IN_PAY_RUN_REQUEST =
  "ADD_EMPLOYEES_IN_PAY_RUN_REQUEST";
export const ADD_EMPLOYEES_IN_PAY_RUN_SUCCESS =
  "ADD_EMPLOYEES_IN_PAY_RUN_SUCCESS";
export const ADD_EMPLOYEES_IN_PAY_RUN_FAILURE =
  "ADD_EMPLOYEES_IN_PAY_RUN_FAILURE";

export const ADD_OFF_CYCLE_RUN = "ADD_OFF_CYCLE_RUN";
export const ADD_OFF_CYCLE_RUN_REQUEST = "ADD_OFF_CYCLE_RUN_REQUEST";
export const ADD_OFF_CYCLE_RUN_SUCCESS = "ADD_OFF_CYCLE_RUN_SUCCESS";
export const ADD_OFF_CYCLE_RUN_FAILURE = "ADD_OFF_CYCLE_RUN_FAILURE";

export const GET_EARNINGS = "GET_EARNINGS";
export const GET_EARNINGS_REQUEST = "GET_EARNINGS_REQUEST";
export const GET_EARNINGS_SUCCESS = "GET_EARNINGS_SUCCESS";
export const GET_EARNINGS_FAILURE = "GET_EARNINGS_FAILURE";

export const GET_DEDUCTIONS = "GET_DEDUCTIONS";
export const GET_DEDUCTIONS_REQUEST = "GET_DEDUCTIONS_REQUEST";
export const GET_DEDUCTIONS_SUCCESS = "GET_DEDUCTIONS_SUCCESS";
export const GET_DEDUCTIONS_FAILURE = "GET_DEDUCTIONS_FAILURE";

export const CREATE_EARNING_OR_DEDUCTION = "CREATE_EARNING_OR_DEDUCTION";
export const CREATE_EARNING_OR_DEDUCTION_REQUEST =
  "CREATE_EARNING_OR_DEDUCTION_REQUEST";
export const CREATE_EARNING_OR_DEDUCTION_SUCCESS =
  "CREATE_EARNING_OR_DEDUCTION_SUCCESS";
export const CREATE_EARNING_OR_DEDUCTION_FAILURE =
  "CREATE_EARNING_OR_DEDUCTION_FAILURE";

export const UPDATE_EARNING_OR_DEDUCTION = "UPDATE_EARNING_OR_DEDUCTION";
export const UPDATE_EARNING_OR_DEDUCTION_REQUEST =
  "UPDATE_EARNING_OR_DEDUCTION_REQUEST";
export const UPDATE_EARNING_OR_DEDUCTION_SUCCESS =
  "UPDATE_EARNING_OR_DEDUCTION_SUCCESS";
export const UPDATE_EARNING_OR_DEDUCTION_FAILURE =
  "UPDATE_EARNING_OR_DEDUCTION_FAILURE";

export const DELETE_EARNING_OR_DEDUCTION = "DELETE_EARNING_OR_DEDUCTION";
export const DELETE_EARNING_OR_DEDUCTION_REQUEST =
  "DELETE_EARNING_OR_DEDUCTION_REQUEST";
export const DELETE_EARNING_OR_DEDUCTION_SUCCESS =
  "DELETE_EARNING_OR_DEDUCTION_SUCCESS";
export const DELETE_EARNING_OR_DEDUCTION_FAILURE =
  "DELETE_EARNING_OR_DEDUCTION_FAILURE";
