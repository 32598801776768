import { lazy } from "react";
import Error from "./pages/404";

const Home = lazy(() => import("./pages/Home"));
const UserList = lazy(() => import("./pages/People/Employees"));
const UserDirectory = lazy(() => import("./pages/People/EmployeesDirectory"));

const EmployeePersonalInfo = lazy(() =>
  import("./pages/Profile/EmployeePersonalInfo")
);
const EmployeeJobInfo = lazy(() => import("./pages/Profile/EmployeeJobInfo"));
const EmployeeContacts = lazy(() => import("./pages/Profile/EmployeeContacts"));
const EmployeeBenefits = lazy(() => import("./pages/Profile/EmployeeBenefits"));
const EmployeeTimeOffs = lazy(() => import("./pages/Profile/EmployeeTimeOffs"));
const EmployeePayStubs = lazy(() => import("./pages/Profile/EmployeePayStubs"));

const BenefitPlans = lazy(() => import("./pages/Benefits/BenefitPlans"));
const Files = lazy(() => import("./pages/Files"));
const BenefitCategories = lazy(() =>
  import("./pages/Benefits/BenefitCategories")
);
const TimeOffs = lazy(() => import("./pages/TimeOffCategories"));
const EmplooyeesOnPayRoll = lazy(() =>
  import("./pages/PayRoll/EmployeesOnPayRoll")
);
const PayRuns = lazy(() => import("./pages/PayRoll/PayRuns"));
const Approval = lazy(() => import("./pages/Approvals/Requests"));
const ApprovalHistories = lazy(() =>
  import("./pages/Approvals/RequestHistories")
);
const TimeOffCalendar = lazy(() => import("./pages/Approvals/Calendar"));
const PaySchedule = lazy(() => import("./pages/PaySchedules"));
const UserOrgChart = lazy(() => import("./pages/People/OrgEmployees"));
const Signin = lazy(() => import("./pages/Signin"));
const Departments = lazy(() => import("./pages/Departments"));
const Error403 = lazy(() => import("./pages/403"));

const Companies = lazy(() =>
  import("./pages/Admin/Companies").then((module) => ({
    default: module?.Companies,
  }))
);

const Users = lazy(() =>
  import("./pages/Admin/Users").then((module) => ({
    default: module?.Users,
  }))
);
const Customers = lazy(() => import("./pages/Admin/Customers"));

const CompanyDetail = lazy(() =>
  import("./pages/CompanyProfile/CompanyDetail").then(({ CompanyDetail }) => ({
    default: CompanyDetail,
  }))
);

const Pages = {
  Home,
  UserList,
  UserDirectory,
  UserOrgChart,
  EmployeePersonalInfo,
  EmployeeJobInfo,
  EmployeeBenefits,
  EmployeeTimeOffs,
  EmployeePayStubs,
  BenefitPlans,
  BenefitCategories,
  Files,
  PaySchedule,
  EmplooyeesOnPayRoll,
  PayRuns,
  TimeOffs,
  EmployeeContacts,
  Signin,
  Approval,
  ApprovalHistories,
  TimeOffCalendar,
  Companies,
  Customers,
  Users,
  CompanyDetail,
  Departments,
  Error403,
  Error,
};
export default Pages;
