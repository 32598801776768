import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/EmployeeType";
import * as Func from "../../utils/functions";
import { parseListRequestParams } from "../../utils/commonUtils";

import * as FunctionAction from "../actions/employeeAction";
import * as AuthAction from "../actions/authAction";
import * as FunctionService from "../services/employeeService";
import { notification } from "antd";

function* getEmployees() {
  yield takeLatest(
    Types.GET_EMPLOYEES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const params = parseListRequestParams(action.filters || {});
      const result = yield call(FunctionService.getEmployees, params);
      yield put(FunctionAction.getEmployeesSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEES_FAILURE))
  );
}

function* getEmployeePersonalInfo() {
  yield takeLatest(
    Types.GET_EMPLOYEE_PERSONAL_INFO_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getEmployeePersonalInfo,
        action.params.id
      );
      yield put(FunctionAction.getEmployeePersonalInfoSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_PERSONAL_INFO_FAILURE))
  );
}

function* updateEmployeePersonalInfo() {
  yield takeLatest(
    Types.UPDATE_EMPLOYEE_PERSONAL_INFO_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateEmployeePersonalInfo,
        action.payload
      );
      yield put(
        FunctionAction.updateEmployeePersonalInfoSuccess(action?.payload)
      );
      notification.success({
        message: "Update Employee Personal Info Successfully",
      });
    }, errorHandle(Types.UPDATE_EMPLOYEE_PERSONAL_INFO_FAILURE))
  );
}

function* getEmployeeJobInfo() {
  yield takeLatest(
    Types.GET_EMPLOYEE_JOB_INFO_REQUEST,
    Func.sagaWrapper(function* (action) {
      const jobInfo = yield call(
        FunctionService.getEmployeeJobInfo,
        action.params
      );
      yield put(FunctionAction.getEmployeeJobInfoSuccess(jobInfo));
    }, errorHandle(Types.GET_EMPLOYEE_JOB_INFO_FAILURE))
  );
}

function* createJobInfo() {
  yield takeLatest(
    Types.CREATE_EMPLOYEE_JOB_INFO_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createEmployeeJobInfo,
        action.data
      );
      yield put(FunctionAction.createEmployeeJobInfoSuccess(result));
      notification.success({
        message: "Update Job Info Successfully",
      });
      yield put(
        FunctionAction.getEmployeeJobInfoRequest({ id: action?.data?.id })
      );
    }, errorHandle(Types.CREATE_EMPLOYEE_JOB_INFO_FAILURE))
  );
}
function* deleteJobInfo() {
  yield takeLatest(
    Types.DELETE_EMPLOYEE_JOB_INFO_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.deleteEmployeeJobInfo,
        action.data
      );
      yield put(FunctionAction.deleteEmployeeJobInfoSuccess(result));
      yield put(
        FunctionAction.getEmployeeJobInfoRequest({ id: action?.data?.userId })
      );
    }, errorHandle(Types.DELETE_EMPLOYEE_JOB_INFO_FAILURE))
  );
}

function* getEmployeeEmploymentStatus() {
  yield takeLatest(
    Types.GET_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const jobInfo = yield call(
        FunctionService.getEmployeeEmploymentStatus,
        action.params
      );
      yield put(FunctionAction.getEmployeeEmploymentStatusSuccess(jobInfo));
    }, errorHandle(Types.GET_EMPLOYEE_EMPLOYMENT_STATUS_FAILURE))
  );
}

function* createEmploymentStatus() {
  yield takeLatest(
    Types.CREATE_EMPLOYMENT_STATUS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createEmploymentStatus,
        action.data
      );
      yield put(FunctionAction.createEmploymentStatusSuccess(result));
      yield put(
        FunctionAction.getEmployeeEmploymentStatusRequest({
          id: action?.data?.id,
        })
      );
    }, errorHandle(Types.CREATE_EMPLOYMENT_STATUS_FAILURE))
  );
}
function* deleteEmploymentStatus() {
  yield takeLatest(
    Types.DELETE_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.deleteEmploymentStatus,
        action.data
      );
      yield put(FunctionAction.deleteEmployeeEmploymentStatusSuccess(result));
      yield put(
        FunctionAction.getEmployeeEmploymentStatusRequest({
          id: action?.data?.userId,
        })
      );
    }, errorHandle(Types.DELETE_EMPLOYEE_EMPLOYMENT_STATUS_FAILURE))
  );
}

function* getEmployeeCompensation() {
  yield takeLatest(
    Types.GET_EMPLOYEE_COMPENSATION_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getEmployeeCompensation,
        action.filters
      );
      yield put(FunctionAction.getEmployeeCompensationSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_COMPENSATION_FAILURE))
  );
}

function* createCompensation() {
  yield takeLatest(
    Types.CREATE_EMPLOYEE_COMPENSATION_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createEmployeeCompensation,
        action.payload
      );
      yield put(FunctionAction.createEmployeeCompensationSuccess(result));
      yield put(
        FunctionAction.getEmployeeCompensationRequest({
          id: action?.payload?.id,
        })
      );
      notification.success({ message: "Update Compensation Successfully" });
    }, errorHandle(Types.CREATE_EMPLOYEE_COMPENSATION_FAILURE))
  );
}
function* deleteCompensation() {
  yield takeLatest(
    Types.DELETE_EMPLOYEE_COMPENSATION_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deleteEmployeeCompensation, action.payload);
      yield put(
        FunctionAction.deleteEmployeeCompensationSuccess(action?.payload)
      );
    }, errorHandle(Types.DELETE_EMPLOYEE_COMPENSATION_FAILURE))
  );
}

function* getEmergencyContacts() {
  yield takeLatest(
    Types.GET_EMPLOYEE_EMERGENCY_CONTACTS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getEmployeeEmergencyContacts,
        action.data
      );
      yield put(FunctionAction.getEmployeeEmergencyContactsSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_EMERGENCY_CONTACTS_FAILURE))
  );
}
function* createEmergencyContact() {
  yield takeLatest(
    Types.CREATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createEmployeeEmergencyContact,
        action.data
      );
      yield put(FunctionAction.createEmployeeEmergencyContactSuccess(result));
      yield put(
        FunctionAction.getEmployeeEmergencyContactsRequest({
          id: action?.data?.id,
        })
      );
      notification?.success({
        message: "Update Employment Status Successfull",
      });
    }, errorHandle(Types.CREATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE))
  );
}
function* updateEmergencyContact() {
  yield takeLatest(
    Types.UPDATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateEmployeeEmergencyContact,
        action.data
      );
      yield put(
        FunctionAction.updateEmployeeEmergencyContactSuccess(action?.data)
      );
      notification?.success({
        message: "Update Emergency Contact Successfull",
      });
    }, errorHandle(Types.UPDATE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE))
  );
}

function* deleteEmergencyContact() {
  yield takeLatest(
    Types.DELETE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.deleteEmployeeEmergencyContact,
        action.data
      );
      yield put(FunctionAction.deleteEmployeeEmergencyContactSuccess(result));
      yield put(
        FunctionAction.deleteEmployeeEmergencyContactSuccess(action.data)
      );
    }, errorHandle(Types.DELETE_EMPLOYEE_EMERGENCY_CONTACT_FAILURE))
  );
}

function* getEmployeeBenefits() {
  yield takeLatest(
    Types.GET_EMPLOYEE_BENEFITS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getEmployeeBenefits,
        action.params
      );
      yield put(FunctionAction.getEmployeeBenefitsSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_BENEFITS_FAILURE))
  );
}

function* createEmployee() {
  yield takeLatest(
    Types.CREATE_EMPLOYEE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.createEmployee, action.data);
      yield put(FunctionAction.createEmployeeSuccess(result));
      yield put(FunctionAction.getEmployeesRequest({ isGettingAll: true }));
      notification?.success({ message: "Add User Successfull" });
    }, errorHandle(Types.CREATE_EMPLOYEE_FAILURE))
  );
}

function* getEmployeesOrg() {
  yield takeLatest(
    Types.GET_ORG_EMPLOYEES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService?.getOrgEmployees, action?.data);
      yield put(FunctionAction.getOrgEmployeesSuccess(result));
    }, errorHandle(Types.GET_ORG_EMPLOYEES_FAILURE))
  );
}

function* getEmployeeTimeOffs() {
  yield takeLatest(
    Types.GET_EMPLOYEE_TIME_OFFS_REQUEST,
    Func.sagaWrapper(function* (action) {
      console.log({ filter: action?.filter });
      const result = yield call(
        FunctionService?.getEmployeeTimeOffs,
        action?.filter
      );
      yield put(FunctionAction.getEmployeeTimeOffsSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_TIME_OFFS_FAILURE))
  );
}

function* updateEmployeeTimeOff() {
  yield takeLatest(
    Types.UPDATE_EMPLOYEE_TIME_OFF_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService?.updateEmployeeTimeOff,
        action?.payload
      );
      yield put(FunctionAction.updateEmployeeTimeOffSuccess(result));
      notification.success({
        message: "Update Request Successfully",
      });
    }, errorHandle(Types.UPDATE_EMPLOYEE_TIME_OFF_FAILURE))
  );
}

function* deleteEmployeeTimeOff() {
  yield takeLatest(
    Types.DELETE_EMPLOYEE_TIME_OFF_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService?.deleteEmployeeTimeOff, action?.params);
      yield put(FunctionAction.deleteEmployeetimeOffSuccess(action?.params));
      notification.success({
        message: "Cancel Request Successfully",
      });
    }, errorHandle(Types.DELETE_EMPLOYEE_TIME_OFF_FAILURE))
  );
}

function* getEmployeePayStubs() {
  yield takeLatest(
    Types.GET_EMPLOYEE_PAY_STUBS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getEmployeePayStubs,
        action.filters
      );
      yield put(FunctionAction.getEmployeePayStubsSuccess(result));
    }, errorHandle(Types.GET_EMPLOYEE_PAY_STUBS_SUCCESS))
  );
}

function* uploadAvatar() {
  yield takeLatest(
    Types.UPLOAD_AVATAR_REQUEST,
    Func.sagaWrapper(function* (action) {
      const { data, onSuccess, onProgress } = action?.payload;
      const result = yield call(FunctionService.uploadAvatar, {
        data,
        onProgress,
      });
      yield put(
        FunctionAction.uploadAvatarEmployeeSuccess({ ...result, onSuccess })
      );
      yield put(AuthAction.checkUserRequest());
    }, errorHandle(Types.UPLOAD_AVATAR_FAILURE))
  );
}

function* changeEmployeeRole() {
  yield takeLatest(
    Types.CHANGE_EMPLOYEE_ROLE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.changeEmployeeRole,
        action?.payload
      );
      yield put(FunctionAction.changeRoleEmployeeSuccess(result));
      notification.success({
        message: "Change Employee Successfully",
      });
    }, errorHandle(Types.UPLOAD_AVATAR_FAILURE))
  );
}

function* changePassword() {
  yield takeLatest(
    Types.CHANGE_PASSWORD_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.changePassword,
        action?.payload
      );
      yield put(FunctionAction.changePasswordEmployeeSuccess(result));
      notification.success({ message: "Change Password Successfully" });
    }, errorHandle(Types.CHANGE_PASSWORD_FAILURE))
  );
}

function* resetPassword() {
  yield takeLatest(
    Types.RESET_PASSWORD_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.resetPassword,
        action?.payload
      );
      yield put(FunctionAction.resetPasswordEmployeeSuccess(result));
      notification.success({ message: "Reset Password Successfully" });
    }, errorHandle(Types.RESET_PASSWORD_FAILURE))
  );
}

function* changeStatusEmployee() {
  yield takeLatest(
    Types.CHANGE_STATUS_EMPLOYEE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.changeStatusEmployee,
        action?.payload
      );
      console.log("========", result)
      yield put(FunctionAction.changeStatusEmployeeSuccess(result));
      notification.success({ message: "Change status Successfully" });
    }, errorHandle(Types.CHANGE_STATUS_EMPLOYEE_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* employeeSaga() {
  yield all([
    fork(getEmployees),
    fork(getEmployeePersonalInfo),
    fork(updateEmployeePersonalInfo),

    fork(getEmployeeJobInfo),
    fork(createJobInfo),
    fork(deleteJobInfo),

    fork(getEmployeeEmploymentStatus),
    fork(createEmploymentStatus),
    fork(deleteEmploymentStatus),

    fork(getEmployeeCompensation),
    fork(createCompensation),
    fork(deleteCompensation),
    fork(getEmployeeBenefits),

    fork(getEmergencyContacts),
    fork(createEmergencyContact),
    fork(updateEmergencyContact),
    fork(deleteEmergencyContact),
    fork(createEmployee),
    fork(getEmployeesOrg),

    fork(getEmployeeTimeOffs),
    fork(updateEmployeeTimeOff),
    fork(deleteEmployeeTimeOff),

    fork(getEmployeePayStubs),
    fork(uploadAvatar),
    fork(changeEmployeeRole),
    fork(changePassword),
    fork(resetPassword),
    fork(changeStatusEmployee)
  ]);
}
