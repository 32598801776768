export const GET_APPROVALS = "GET_APPROVALS";
export const GRANT_APPROVAL = "GRANT_APPROVAL";
export const GET_APPROVAL = "GET_APPROVAL";

export const GET_APPROVALS_REQUEST = "GET_APPROVALS_REQUEST";
export const GET_APPROVALS_SUCCESS = "GET_APPROVALS_SUCCESS";
export const GET_APPROVALS_FAILURE = "GET_APPROVALS_FAILURE";

export const GRANT_APPROVAL_REQUEST = "GRANT_APPROVAL_REQUEST";
export const GRANT_APPROVAL_SUCCESS = "GRANT_APPROVAL_SUCCESS";
export const GRANT_APPROVAL_FAILURE = "GRANT_APPROVAL_FAILURE";

export const GET_APPROVAL_REQUEST = "GET_APPROVAL_REQUEST";
export const GET_APPROVAL_SUCCESS = "GET_APPROVAL_SUCCESS";
export const GET_APPROVAL_FAILURE = "GET_APPROVAL_FAILURE";

export const GET_APPROVAL_HISTORIES = "GET_APPROVAL_HISTORIES";
export const GET_APPROVAL_HISTORIES_REQUEST = "GET_APPROVAL_HISTORIES_REQUEST";
export const GET_APPROVAL_HISTORIES_SUCCESS = "GET_APPROVAL_HISTORIES_SUCCESS";
export const GET_APPROVAL_HISTORIES_FAILURE = "GET_APPROVAL_HISTORIES_FAILURE";
