import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/ApprovalType";
import * as Func from "../../utils/functions";

import * as FunctionAction from "../actions/approvalAction";
import * as FunctionService from "../services/approvalService";
import { notification } from "antd";

function* getApprovals() {
  yield takeLatest(
    Types.GET_APPROVALS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getApprovals);
      yield put(FunctionAction.getApprovalsSuccess(result));
    }, errorHandle(Types.GET_APPROVALS_FAILURE))
  );
}

function* getApprovalHistories() {
  yield takeLatest(
    Types.GET_APPROVAL_HISTORIES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getApprovalHistories);
      yield put(FunctionAction.getApprovalHistoriesSuccess(result));
    }, errorHandle(Types.GET_APPROVAL_HISTORIES_FAILURE))
  );
}

function* grantApproval() {
  yield takeLatest(
    Types.GRANT_APPROVAL_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.grantApproval, action?.payload);
      yield put(
        FunctionAction.grantApprovalSuccess(result)
      );
      notification?.success({
        message: `Time off request was successfully ${
          action?.payload?.approval ? "approved" : "denied"
        }`,
      });
    }, errorHandle(Types.GRANT_APPROVAL_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* approvalSaga() {
  yield all([
    fork(grantApproval),
    fork(getApprovals),
    fork(getApprovalHistories),
  ]);
}
