import { pick } from "lodash";
import moment from "moment";
import { getPhone } from "../../../utils/commonUtils";

export default class CustomerResponse {
  constructor(data = {}) {
    this.setData(data);
  }

  getKeys() {
    return Object.keys(this);
  }

  setData(data = {}) {
    this.id = data?.id || "";
    this.firstName = data?.first_name || "";
    this.lastName = data?.last_name || "";
    this.fullName = data?.full_name || "";
    this.email = data?.email || "";
    this.phoneNumber = getPhone(data?.phone_number, data?.country);
    this.phoneNumberTxt = data?.phone_number || "";
    this.companyId = data?.company_id || "";
    this.status = data?.status;
    this.avatar =
      data?.avatar ||
      `https://ui-avatars.com/api/?bold=true&color=ffffff&background=015a8f&name=${data?.first_name} ${data?.last_name}`;
    this.dateOfBirth = moment(+data?.date_of_birth || null);
    this.gender = data?.gender === 1 ? "Male" : "Female";
    this.country = data?.country || "";
    this.address = data?.address || "";
    this.roles = (data?.roles || data?.role_list || []).map(
      (role) => role?.name
    );
  }

  exportList() {
    return pick(this, [
      "id",
      "firstName",
      "lastName",
      "fullName",
      "email",
      "phoneNumber",
      "phoneNumberTxt",
      "companyId",
      "status",
      "avatar",
      "dateOfBirth",
      "gender",
      "country",
      "address",
      "roles",
    ]);
  }

  exportItem() {
    return pick(this, [
      "id",
      "firstName",
      "lastName",
      "fullName",
      "email",
      "phoneNumber",
      "phoneNumberTxt",
      "companyId",
      "status",
      "avatar",
      "dateOfBirth",
      "gender",
      "country",
      "address",
      "roles",
    ]);
  }
}
