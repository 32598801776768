import * as Types from "../types/BenefitType";

const initialState = {
  benefitPlans: [],
  benefitCategories: [],
};

const benefitReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.GET_BENEFIT_PLANS_SUCCESS: {
      const benefitPlans = action?.result;
      return { ...state, benefitPlans };
    }
    case Types.UPDATE_BENEFIT_PLAN_SUCCESS: {
      const benefitPlans = state?.benefitPlans?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return { ...state, benefitPlans };
    }
    case Types.DELETE_BENEFIT_PLAN_SUCCESS: {
      const benefitPlans = state.benefitPlans?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return { ...state, benefitPlans };
    }
    case Types.GET_BENEFIT_CATEGORIES_SUCCESS: {
      const benefitCategories = action?.result;
      return {
        ...state,
        benefitCategories,
      };
    }
    case Types.CREATE_BENEFIT_CATEGORY_SUCCESS: {
      const benefitCategories = [action?.result, ...state?.benefitCategories];
      return {
        ...state,
        benefitCategories,
      };
    }
    case Types.UPDATE_BENEFIT_CATEGORY_SUCCESS: {
      const benefitCategories = state?.benefitCategories?.map((item) => {
        if (item?.id === action?.result?.id) {
          return action?.result;
        }
        return item;
      });
      return {
        ...state,
        benefitCategories,
      };
    }
    case Types.DELETE_BENEFIT_CATEGORY_SUCCESS: {
      const benefitCategories = state.benefitCategories?.filter(
        (item) => item?.id !== action?.result?.id
      );
      return { ...state, benefitCategories };
    }
    default:
      return state;
  }
};
export default benefitReducer;
