import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getCompanies(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_COMPANIES,
    parser: (data) => data.data.result,
  }).get();
}

export function getCompany(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_COMPANY,
    params: params,
    parser: (data) => data.data.result,
  }).get();
}

export function createCompany(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_COMPANY,
    parser: (data) => data.data.result,
  }).post(payload);
}

export function updateCompany(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_COMPANY,
    parser: (data) => data.data.result,
  }).put(payload);
}

export function deleteCompany(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_COMPANY,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function changeCompanyStatus(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CHANGE_COMPANY_STATUS,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).put(payload);
}

export function uploadCompanyLogo(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPLOAD_COMPANY_LOGO,
    parser: (data) => data.data.result,
    onUploadProgress: ({ loaded, total }) => {
      payload?.onProgress({
        percent: parseFloat(Math.round((loaded / total) * 100)),
      });
    },
  }).post(payload?.data);
}
