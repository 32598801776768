import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getEmployeesOnPayRoll() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EMPLOYEES_ON_PAY_ROLL,
    parser: (res) => res.data.result,
  }).get();
}

export function getDraftPayRuns() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_DRAFT_PAY_RUNS,
    parser: (res) => res.data.result,
  }).get();
}

export function getApprovedPayRuns() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_APPROVED_PAY_RUNS,
    parser: (res) => res.data.result,
  }).get();
}

export function getPaidPayRuns() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_PAID_PAY_RUNS,
    parser: (res) => res.data.result,
  }).get();
}

export function approveDraftPayRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.APPROVE_DRAFT_PAY_RUN,
    parser: (res) => res.data.result,
  }).post(payload);
}

export function revertApprovedPayRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.REVERT_APPROVED_PAY_RUN,
    parser: (res) => res.data.result,
  }).post(payload);
}

export function addEmployeeOnPayRoll(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.ADD_EMPLOYEE_ON_PAY_ROLL,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).post(payload);
}

export function removeEmployeeOnPayRoll(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.REMOVE_EMPLOYEE_ON_PAY_ROLL,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).post(payload);
}

export function updatePayRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_PAY_RUN,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).put(payload);
}

export function deletePayRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_PAY_RUN,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).delete();
}

export function addEmployeeInPayRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.ADD_EMPLOYEE_IN_PAY_RUN,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).post(payload);
}

export function updateEmployeeInPayRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_EMPLOYEE_IN_PAY_RUN,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).put(payload);
}

export function addOffCycleRun(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.ADD_OFF_CYCLE_RUN,
    parser: (res) => res.data.result,
  }).post(payload);
}

export function getEarnings() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EARNINGS,
    parser: (res) => res.data.result,
  }).get();
}

export function getDeductions() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_DEDUCTIONS,
    parser: (res) => res.data.result,
  }).get();
}

export function createEarningOrDeduction(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_EARNING_OR_DEDUCTION,
    parser: (res) => res.data.result,
  }).post(payload);
}

export function updateEarningOrDeduction(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_EARNING_OR_DEDUCTION,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).put(payload);
}

export function deleteEarningOrDeduction(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_EARNING_OR_DEDUCTION,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).delete();
}
