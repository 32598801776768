const TAG_DEFINE = {
  VALIDATION: {
    statusCode: {
      code: {
        200: "Upload successful",
        201: "Create successful",
        204: "Update data successful",
        203: "Delete data successful",
        400: "Không cập không hiệu lực",
        401: "Please login to the page!",
        403: "You do not have permission to access this site!.",
        404: "Page Not Found",
        405: "Không hỗ trợ",
      },
    },
  },
  ACTION_OPTIONS: {
    CREATE: "CREATE",
    CLONE: "CLONE",
    UPDATE: "UPDATE",
    TRANSLATE: "TRANSLATE",
  },
  ROLES: ["Administrator", "Manager", "Employee"],
};
export default TAG_DEFINE;
