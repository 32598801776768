import { CONFIG_LOCAL_STORAGE } from "../constants/define";

export const get = (key) => {
  const storageKey = `${CONFIG_LOCAL_STORAGE.PREFIX}_${key}`;
  const stringData = localStorage.getItem(storageKey);
  const numberRegex = RegExp("^[1-9.]+$");
  if (stringData === "true" || stringData === "false") {
    return stringData === "true";
  } else if (stringData === "null") {
    return null;
  } else if (stringData === "undefined") {
    return undefined;
  } else if (numberRegex.test(stringData)) {
    return Number(stringData);
  } else {
    try {
      const data = JSON.parse(stringData);
      return data;
    } catch (error) {
      return stringData;
    }
  }
};

export const set = (key, data) => {
  const storageKey = `${CONFIG_LOCAL_STORAGE.PREFIX}_${key}`;
  let stringData = undefined;
  try {
    if (typeof data === "object") {
      stringData = JSON.stringify(data);
    } else {
      stringData = String(data);
    }
  } catch (error) {
    stringData = data;
  }
  return localStorage.setItem(storageKey, stringData);
};

export const remove = (key) =>
  localStorage.removeItem(`${CONFIG_LOCAL_STORAGE.PREFIX}_${key}`);
