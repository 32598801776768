import * as Types from "../types/AdminAccountTypes";

// GET LIST
export const getAdminAccountsRequest = (params) => ({
  type: Types.GET_ADMIN_ACCOUNTS_REQUEST,
  params,
});
export const getAdminAccountsSuccess = (result) => ({
  type: Types.GET_ADMIN_ACCOUNTS_SUCCESS,
  result,
});

// CREATE
export const createAdminAccountRequest = (payload) => ({
  type: Types.CREATE_ADMIN_ACCOUNT_REQUEST,
  payload,
});
export const createAdminAccountSuccess = (result) => ({
  type: Types.CREATE_ADMIN_ACCOUNT_SUCCESS,
  result,
});

// UPDATE
export const updateAdminAccountRequest = (payload) => ({
  type: Types.UPDATE_ADMIN_ACCOUNT_REQUEST,
  payload,
});
export const updateAdminAccountSuccess = (result) => ({
  type: Types.UPDATE_ADMIN_ACCOUNT_SUCCESS,
  result,
});

// DELETE
export const deleteAdminAccountRequest = (params) => ({
  type: Types.DELETE_ADMIN_ACCOUNT_REQUEST,
  params,
});
export const deleteAdminAccountSuccess = (result) => ({
  type: Types.DELETE_ADMIN_ACCOUNT_SUCCESS,
  result,
});

// BLOCK
export const blockAdminAccountRequest = (params) => ({
  type: Types.BLOCK_ADMIN_ACCOUNT_REQUEST,
  params,
});
export const blockAdminAccountSuccess = (result) => ({
  type: Types.BLOCK_ADMIN_ACCOUNT_SUCCESS,
  result,
});
