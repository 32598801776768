import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

import DepartmentResponse from "../mapping/Response/DepartmentResponse";

export function getDepartments(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_DEPARTMENTS,
    parser: dataParser,
  }).get();
}

export function createDepartment(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_DEPARTMENT,
    endpointParams: { company_id: payload?.company_id },
    parser: (data) => new DepartmentResponse(data.data.result).exportItem(),
  }).post(payload);
}

export function updateDepartment(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_DEPARTMENT,
    endpointParams: { departmentId: payload?.id },
    parser: (data) => new DepartmentResponse(data.data.result).exportItem(),
  }).put(payload);
}

export function deleteDepartment(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_DEPARTMENT,
    endpointParams: { departmentId: params?.id },
  }).delete();
}

function dataParser(data = {}) {
  return (data.data.result || []).map((item) =>
    new DepartmentResponse(item).exportList()
  );
}
