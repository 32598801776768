import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getFiles(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_GET_LIST,
    parser: (data) => data.data.result,
  }).get();
}

export function getFolders() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_GET_FOLDERS,
    parser: (data) => data.data.result,
  }).get();
}

export function createFolder(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_ADD_FOLDER,
    parser: (data) => data.data.result,
  }).post(payload);
}

export function updateFolder(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_EDIT_FOLDER,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).put(payload);
}

export function deleteFolder(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_DELETE_FOLDER,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function deleteFile(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_DELETE_ITEM,
    parser: (data) => data.data.result,
  }).delete(params);
}

export function uploadFile(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.FILE_UPLOAD,
    parser: (data) => data.data.result,
    onUploadProgress: ({ loaded, total }) => {
      payload?.onProgress({
        percent: parseFloat(Math.round((loaded / total) * 100)),
      });
    },
  }).post(payload?.data);
}
