import { notification, message } from "antd";
import { call, put, takeLatest, fork, all, delay } from "redux-saga/effects";

import * as Func from "../../utils/functions";
import * as Types from "../types/PayScheduleType";
import * as FunctionAction from "../actions/payScheduleAction";
import * as FunctionService from "../services/payScheduleService";
import TAG_DEFINE from "../../constants/common";

function* getPaySchedules() {
  yield takeLatest(
    Types.GET_PAY_SCHEDULES_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(FunctionService.getPaySchedules);
      yield put(FunctionAction.getPaySchedulesSuccess(result));
    }, errorHandle(Types.GET_PAY_SCHEDULES_FAILURE))
  );
}
function* createPaySchedule() {
  yield takeLatest(
    Types.CREATE_PAY_SCHEDULE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.addPaySchedule,
        action?.payload
      );
      // message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
      yield put(FunctionAction.createPayScheduleSuccess(result));
      notification.success({
        message: TAG_DEFINE.VALIDATION.statusCode.code["201"],
      });
    }, errorHandle(Types.CREATE_PAY_SCHEDULE_FAILURE))
  );
}
function* updatePaySchedule() {
  yield takeLatest(
    Types.UPDATE_PAY_SCHEDULE_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.editPaySchedule,
        action?.payload
      );
      yield put(FunctionAction.updatePayScheduleSuccess(result));
      notification.success({ message: "Update The Pay Schedule Success" });
    }, errorHandle(Types.UPDATE_PAY_SCHEDULE_FAILURE))
  );
}
function* deletePaySchedule() {
  yield takeLatest(
    Types.DELETE_PAY_SCHEDULE_REQUEST,
    Func.sagaWrapper(function* (action) {
      yield call(FunctionService.deletePaySchedule, action?.params);
      yield put(FunctionAction.deletePayScheduleSuccess(action?.params));
      notification.success({
        message: TAG_DEFINE.VALIDATION.statusCode.code["203"],
      });
      // notification.success({ message: "Delete The Pay Schedule Success" });
    }, errorHandle(Types.DELETE_PAY_SCHEDULE_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* payScheduleSaga() {
  yield all([
    fork(getPaySchedules),
    fork(createPaySchedule),
    fork(updatePaySchedule),
    fork(deletePaySchedule),
  ]);
}
