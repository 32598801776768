import { notification } from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import * as Types from "../types/AdminAccountTypes";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/adminAccountAction";
import * as FunctionService from "../services/adminAccountService";

function* getAdminAccounts() {
  yield takeLatest(
    Types.GET_ADMIN_ACCOUNTS_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.getAdminAccounts,
        action?.params
      );
      yield put(FunctionAction.getAdminAccountsSuccess(result));
    }, errorHandle(Types.GET_ADMIN_ACCOUNTS_FAILURE))
  );
}

function* createAdminAccount() {
  yield takeLatest(
    Types.CREATE_ADMIN_ACCOUNT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.createAdminAccount,
        action?.payload
      );
      yield put(FunctionAction.createAdminAccountSuccess(result));
      notification.success({ message: "Create Successfully" });
    }, errorHandle(Types.CREATE_ADMIN_ACCOUNT_FAILURE))
  );
}

function* updateAdminAccount() {
  yield takeLatest(
    Types.UPDATE_ADMIN_ACCOUNT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.updateAdminAccount,
        action?.payload
      );
      yield put(FunctionAction.updateAdminAccountSuccess(result));
      notification.success({ message: "Update Successfully" });
    }, errorHandle(Types.UPDATE_ADMIN_ACCOUNT_FAILURE))
  );
}

function* deleteAdminAccount() {
  yield takeLatest(
    Types.DELETE_ADMIN_ACCOUNT_REQUEST,
    Func.sagaWrapper(function* (action) {
      const result = yield call(
        FunctionService.deleteAdminAccount,
        action?.params
      );
      yield put(FunctionAction.deleteAdminAccountSuccess(action?.params));
      notification.success({ message: "Delete Successfully" });
    }, errorHandle(Types.DELETE_ADMIN_ACCOUNT_FAILURE))
  );
}

function errorHandle(errorActionType) {
  return Func.sagaErrorHandler(function* (e) {
    if (e?.errors) {
      notification?.error({ message: e?.errors[0]?.message });
    }
    yield put({ type: errorActionType, errors: e?.errors });
  });
}

export function* adminAccountSaga() {
  yield all([
    fork(getAdminAccounts),
    fork(createAdminAccount),
    fork(updateAdminAccount),
    fork(deleteAdminAccount),
  ]);
}
