import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as HookAction from "../actions/authAction";
import { selectMe, selectSelfCompany } from "../reselects/authSelector";

export const useGetMe = () => {
  const me = useSelector(selectMe());
  return me || {};
};

export const useGetMyRoles = () => {
  const me = useSelector(selectMe());
  return me?.role || {};
};

export const useGetCompanyFromToken = () => {
  const company = useSelector(selectSelfCompany());
  return company || {};
};

export const useCheckAuth = () => {
  const dispatch = useDispatch();
  return useCallback(
    (params) => dispatch(HookAction.checkUserRequest(params)),
    [dispatch]
  );
};

export const useUpdateProfile = () => {
  const dispatch = useDispatch();
  return useCallback(
    (params) => dispatch(HookAction.updateProfileRequest(params)),
    [dispatch]
  );
};
