import * as Types from "../types/DepartmentTypes";

// GET LIST
export const getDepartmentsRequest = (params) => ({
  type: Types.GET_DEPARTMENTS_REQUEST,
  params,
});
export const getDepartmentsSuccess = (result) => ({
  type: Types.GET_DEPARTMENTS_SUCCESS,
  result,
});

// CREATE
export const createDepartmentRequest = (payload) => ({
  type: Types.CREATE_DEPARTMENT_REQUEST,
  payload,
});
export const createDepartmentSuccess = (result) => ({
  type: Types.CREATE_DEPARTMENT_SUCCESS,
  result,
});

// UPDATE
export const updateDepartmentRequest = (payload) => ({
  type: Types.UPDATE_DEPARTMENT_REQUEST,
  payload,
});
export const updateDepartmentSuccess = (result) => ({
  type: Types.UPDATE_DEPARTMENT_SUCCESS,
  result,
});

// DELETE
export const deleteDepartmentRequest = (params) => ({
  type: Types.DELETE_DEPARTMENT_REQUEST,
  params,
});
export const deleteDepartmentSuccess = (result) => ({
  type: Types.DELETE_DEPARTMENT_SUCCESS,
  result,
});
