import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";
import UserResponse from "../mapping/Response/UserResponse";

export function getEmployees(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_LIST,
    params: params,
    parser: dataParser,
  }).get();
}

export function getEmployeePersonalInfo(id) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_GET_PERSONAL_INFO,
    endpointParams: { id },
    parser: (data) => new UserResponse(data.data.result).exportItem(),
  }).get();
}
export function updateEmployeePersonalInfo(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_PUT_PERSONAL_INFO,
    endpointParams: { id: data?.id },
    parser: (data) => new UserResponse(data.data.result).exportItem(),
  }).put(data);
}

export function getEmployeeJobInfo(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_GET_JOB_INFO,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).get();
}

export function createEmployeeJobInfo(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_POST_JOB_INFO,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).post(data);
}
export function deleteEmployeeJobInfo(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_DELETE_JOB_INFO,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function getEmployeeEmploymentStatus(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EMPLOYEE_EMPLOYMENT_STATUS,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).get();
}

export function createEmploymentStatus(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_POST_EMPLOYMENT_STATUS,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).post(data);
}
export function deleteEmploymentStatus(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_DELETE_EMPLOYMENT_STATUS,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function getEmployeeCompensation(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EMPLOYEE_COMPENSATION,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).get();
}

export function createEmployeeCompensation(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_POST_COMPENSATION,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).post(payload);
}
export function deleteEmployeeCompensation(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_DELETE_COMPENSATION,
    endpointParams: { id: data?.id },
  }).delete();
}

export function getEmployeeEmergencyContacts(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_GET_EMERGENCY_CONTACT,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).get();
}
export function createEmployeeEmergencyContact(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_POST_EMERGENCY_CONTACT,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).post(data);
}
export function updateEmployeeEmergencyContact(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_PUT_EMERGENCY_CONTACT,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).put(data);
}
export function deleteEmployeeEmergencyContact(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_DELETE_EMERGENCY_CONTACT,
    endpointParams: { id: data?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function getEmployeeBenefits(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_GET_BENEFITS,
    endpointParams: { id: params?.id },
    parser: (res) => res.data.result,
  }).get();
}

export function createEmployee(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_ADD,
  }).post(data);
}

export function getOrgEmployees(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_ORG,
    parser: (data) => data?.data?.result,
  }).post(data);
}

export function getEmployeeTimeOffs(filter) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_GET_TIMEOFFS,
    endpointParams: { id: filter?.id },
    parser: (data) => data?.data?.result,
  }).get(filter);
}

export function updateEmployeeTimeOff(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_PUT_TIMEOFF,
    parser: (data) => data?.data?.result,
    endpointParams: { id: payload?.id },
  }).put(payload);
}

export function deleteEmployeeTimeOff(result) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.EMPLOYEE_DELETE_TIMEOFF,
    parser: (data) => data?.data?.result,
    endpointParams: { id: result?.id },
  }).delete(result);
}

export function getEmployeePayStubs(filters) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EMPLOYEE_PAY_STUBS,
    endpointParams: { id: filters?.id },
    parser: (data) => data?.data?.result,
  }).get();
}

export function getEmployeePayStub(filters) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EMPLOYEE_PAY_STUB,
    endpointParams: { id: filters?.id },
    parser: (data) => data?.data?.result,
  }).get();
}

export function uploadAvatar(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPLOAD_AVATAR,
    parser: (data) => data.data.result,
    onUploadProgress: ({ loaded, total }) => {
      payload?.onProgress({
        percent: parseFloat(Math.round((loaded / total) * 100)),
      });
    },
  }).post(payload?.data);
}

export function changeEmployeeRole(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CHANGE_EMPLOYEE_ROLE,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).put(payload);
}

export function changePassword(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CHANGE_PASSWORD_EMPLOYEE,
    parser: (data) => data.data.result,
  }).put(payload);
}

export function resetPassword(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.RESET_PASSWORD_EMPLOYEE,
    parser: (data) => data?.data?.result,
  }).put(payload);
}

export function changeStatusEmployee(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CHANGE_STATUS_EMPLOYEE,
    parser: (data) => data?.data?.result,
  }).put(payload);
}

function dataParser(data = {}) {
  return {
    ...data.data.result,
    result: (data.data.result.data || []).map((item) =>
      new UserResponse(item).exportList()
    ),
    total_page: Math.ceil(
      parseInt(data.data.result.total) / parseInt(data.data.page_size)
    ),
  };
}
