import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getBenefitPlans() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.BENEFIT_GET_PLANS,
    parser: (data) => data.data.result,
  }).get();
}
export function createBenefitPlan(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.BENEFIT_ADD_PLAN,
    parser: (res) => res.data.result,
  }).post(payload);
}
export function updateBenefitPlan(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.BENEFIT_EDIT_PLAN,
    endpointParams: { id: payload?.id },
    parser: (res) => res.data.result,
  }).put(payload);
}
export function deleteBenefitPlan(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.BENEFIT_DELETE_PLAN,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).delete();
}

export function getBenefitCategories() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_BENEFIT_CATEGORIES,
    parser: (data) => data.data.result,
  }).get();
}

export function createBenefitCategory(data) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_BENEFIT_CATEGORY,
    parser: (data) => data.data.result,
  }).post(data);
}

export function updateBenefitCategory(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_BENEFIT_CATEGORY,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).put(payload);
}

export function deleteBenefitCategory(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_BENEFIT_CATEGORY,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).delete();
}
