import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getApprovals() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_TIME_OFF_APPROVALS,
    parser: (data) => data.data.result,
  }).get();
}

export function getApprovalHistories() {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_TIME_OFF_APPROVAL_HISTORIES,
    parser: (data) => data.data.result,
  }).get();
}

export function grantApproval(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.PERMIT_TIME_OFF_APPROVAL,
    parser: (data) => data.data.result,
  }).post(payload);
}
