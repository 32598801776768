import {removeObjectNullFull} from "../../../utils/functions";

export default class AuthRequest {
    constructor(data = {}) {
        this.setData(data)
    }

    setData(data = {}) {
        this.id = data?.id || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.email = data?.email || "";
        this.password = data?.password || "";
    }

    exportUpdate() {
        return removeObjectNullFull({
            id: this.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
        })
    }
}
