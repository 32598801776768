import React from "react";
import { Redirect, Route } from "react-router-dom";
import connect from "react-redux/es/connect/connect";

import Signin from "./pages/Signin";

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { authentication } = rest;
      return !authentication?.logged ? (
        <>
          <Signin>
            <Component {...props} />
          </Signin>
        </>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: rest.location } }} />
      );
    }}
  />
);

const mapStateToProps = (state) => ({
  authentication: state.authReducer,
});

export default connect(mapStateToProps)(LoginRoute);
