import { PAGINATION } from "../constants/define";
import { omit } from "lodash";
import moment from "moment";

import { PAY_PERIOD_OPTIONS, PAY_RULES } from "../constants/payPeriod";

export function parseListRequestParams(params = {}) {
  const page_index =
    params.page_index && Number(params.page_index) > PAGINATION.page_index
      ? params.page_index
      : undefined;
  const page_size =
    params.page_size && Number(params.page_size) >= PAGINATION.page_size
      ? params.page_size
      : undefined;
  const order_by = params.order_by || undefined;
  const filter = params.filter || params.filter || undefined;
  const order_by_desc =
    params.order_by_desc !== undefined
      ? params.order_by_desc
      : params.order_by_desc !== undefined
      ? params.order_by_desc
      : undefined;
  const start_date = params.start_date
    ? moment(params.start_date).toISOString()
    : undefined;
  const end_date = params.end_date
    ? moment(params.end_date).toISOString()
    : undefined;
  return {
    ...omit(params, [
      "page_index",
      "page_size",
      "order_by",
      "order_by_desc",
      "result",
      "filter",
    ]),
    page_index,
    page_size,
    order_by,
    order_by_desc,
    start_date,
    end_date,
    filter,
  };
}

export const getPhone = (number, short) => {
  if (number && short) {
    const phon1 = number?.toString().split(" ");
    const idxPlus = phon1[0].indexOf("+");
    const code = phon1[0].slice(idxPlus + 1);
    const phone = phon1[1];
    return { code, phone, short };
  }
  return {
    code: 1,
    phone: "",
    short: "us",
  };
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getIdEmployee = (match, userId) => {
  let id = match?.params?.id;
  if (!id) {
    const arr = match?.path?.split("/");
    if (arr.includes("me")) {
      id = userId;
    }
  }
  return id;
};
