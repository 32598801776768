import React, { useState, useRef, useEffect } from "react";
import { Button, Form, Input } from "antd";
import { connect } from "react-redux";
import { signinRequest } from "../../../data/actions/authAction";
import { createLoadingSelector } from "../../../data/reselects/loadingSelector";
import * as Types from "../../../data/types/AuthType";
import { SyncOutlined } from "@ant-design/icons";

const Signin = (props) => {
  const { isFetching } = props;

  const onPreSignin = (values) => {
    const email = values["email"];
    const password = values["password"];
    props.onSignin({
      email,
      password,
    });
  };

  return (
    <>
      <div className='login'>
        <section className='login__form'>
          <div className='card p-3'>
            <div className='card__header'>Sign In</div>
            <div className='card__body'>
              <Form onFinish={onPreSignin}>
                <Form.Item name='email'>
                  <Input
                    size='large'
                    type='email'
                    name='email'
                    required
                    placeholder='Email'
                  />
                </Form.Item>
                <Form.Item name='password'>
                  <Input
                    size='large'
                    type='password'
                    name='password'
                    required
                    placeholder='Password'
                  />
                </Form.Item>
                <div className='login__button'>
                  <Button
                    type='primary'
                    size='middle'
                    shape='round'
                    disabled={isFetching}
                    htmlType='submit'>
                    Access {isFetching && <SyncOutlined spin />}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const loadingSelector = createLoadingSelector([Types.SIGNIN]);
const mapStateToProps = (state) => ({
  isFetching: loadingSelector(state),
  checkAuth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSignin: (params) => {
      dispatch(signinRequest(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
