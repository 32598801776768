import * as Types from "../types/AuthType";

const initialState = {
  logging: false,
  logged: !!localStorage.getItem("hn_utk"),
  companies: [],
  user: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_PROFILE_SUCCESS:
      return { ...state, data: action.updateProfile };
    case Types.CHECK_USER_SUCCESS: {
      const superAdminMenu = [
        {
          name: "companies",
          items: [{ name: "details", route: "companies.list" }],
        },
        {
          name: "customers",
          items: [
            {
              name: "customers",
              route: "customers.account-management",
            },
          ],
        },
        {
          name: "users",
          items: [
            {
              name: "users",
              route: "users.account-management",
            },
          ],
        },
      ];
      const isSuperAdmin = action?.result?.user?.isSuperAdmin;
      return {
        ...state,
        logged: true,
        user: isSuperAdmin
          ? {
              ...action?.result,
              menu: [...action?.result?.menu, ...superAdminMenu],
            }
          : action?.result,
      };
    }
    case Types.SIGNIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        logged: true,
        logging: false,
      };
    case Types.SIGNIN_FAILURE:
      return {
        ...state,
        logged: false,
      };
    case Types.SIGNOUT_SUCCESS:
      return {
        ...state,
        companies: [],
        logging: false,
        logged: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default authReducer;
