import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function getAdminAccounts(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_ADMIN_ACCOUNTS,
    parser: (data) => data.data.result,
  }).get();
}

export function createAdminAccount(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_ADMIN_ACCOUNT,
    parser: (data) => data.data.result,
  }).post(payload);
}

export function updateAdminAccount(payload) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.UPDATE_ADMIN_ACCOUNT,
    endpointParams: { id: payload?.id },
    parser: (data) => data.data.result,
  }).put(payload);
}

export function deleteAdminAccount(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_ADMIN_ACCOUNT,
    endpointParams: { id: params?.id },
    parser: (data) => data.data.result,
  }).delete();
}
