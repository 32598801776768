export const GET_FILES = "GET_FILES";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const GET_FOLDERS = "GET_FOLDERS";
export const CREATE_FOLDER = "CREATE_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";

export const GET_FILES_REQUEST = "GET_FILES_REQUEST";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAILURE = "GET_FILES_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const CREATE_FOLDER_REQUEST = "CREATE_FOLDER_REQUEST";
export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";

export const UPDATE_FOLDER_REQUEST = "UPDATE_FOLDER_REQUEST";
export const UPDATE_FOLDER_SUCCESS = "UPDATE_FOLDER_SUCCESS";
export const UPDATE_FOLDER_FAILURE = "UPDATE_FOLDER_FAILURE";

export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";

export const GET_FOLDERS_REQUEST = "GET_FOLDERS_REQUEST";
export const GET_FOLDERS_SUCCESS = "GET_FOLDERS_SUCCESS";
export const GET_FOLDERS_FAILURE = "GET_FOLDERS_FAILURE";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";
