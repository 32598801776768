import { pick } from "lodash";

export default class DepartmentResponse {
  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    this.id = data?.id || "";
    this.name = data?.name || "";
  }

  exportList() {
    return pick(this, ["id", "name"]);
  }

  exportItem() {
    return pick(this, ["id", "name"]);
  }
}
