export default class UserResponse {
  constructor(data = {}) {
    this.setData(data);
  }

  setData(data = {}) {
    // this.id = data?.id || "";
    // this.email = data?.email || "";
    // this.avatar = data?.avatar || "";
    // this.role = data?.role || "user";
    // this.phone_number = data?.phone_number || "";
    // this.account_manager = data?.account_manager || null;
    this.employee = data?.employee;
    this.personal_info = data?.personal_info;
  }

  exportList() {
    return {
      id: this.id,
      email: this.email,
      avatar: this.avatar,
      first_name: this.first_name,
      last_name: this.last_name,
      role: this.role,
      phone_number: this.phone_number,
      account_manager: this.account_manager,
    };
  }

  exportItem() {
    return {
      // id: this.id,
      // email: this.email,
      // avatar: this.avatar,
      // first_name: this.first_name,
      // last_name: this.last_name,
      // role: this.role,
      // phone_number: this.phone_number,
      // account_manager: this.account_manager?.id,
      employee: this?.employee,
      personal_info: this?.personal_info,
    };
  }
}
