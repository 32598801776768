import { createSelector } from "reselect";

const stateReducer = (state) => state.authReducer;

export const selectMe = (initData) =>
  createSelector(stateReducer, (authReducer) => authReducer.user?.user);

export const selectSelfCompany = (initData) =>
  createSelector(stateReducer, (authReducer) => authReducer.user?.company);

export const selectFetching = (initData) =>
  createSelector(stateReducer, (authReducer) => authReducer.isFetching);
