import * as Types from "../types/EmployeeType";

export const getEmployeesRequest = (filters) => ({
  type: Types.GET_EMPLOYEES_REQUEST,
  filters,
});
export const getEmployeesSuccess = (result) => ({
  type: Types.GET_EMPLOYEES_SUCCESS,
  result,
});

export const createEmployeeRequest = (data) => ({
  type: Types.CREATE_EMPLOYEE_REQUEST,
  data,
});
export const createEmployeeSuccess = (result) => ({
  type: Types.CREATE_EMPLOYEE_SUCCESS,
  result,
});

export const getOrgEmployeesRequest = (data) => ({
  type: Types.GET_ORG_EMPLOYEES_REQUEST,
  data,
});

export const getOrgEmployeesSuccess = (result) => ({
  type: Types.GET_ORG_EMPLOYEES_SUCCESS,
  result,
});

export const getEmployeePersonalInfoRequest = (params) => ({
  type: Types.GET_EMPLOYEE_PERSONAL_INFO_REQUEST,
  params,
});
export const getEmployeePersonalInfoSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_PERSONAL_INFO_SUCCESS,
  result,
});

export const updateEmployeePersonalInfoRequest = (payload) => ({
  type: Types.UPDATE_EMPLOYEE_PERSONAL_INFO_REQUEST,
  payload,
});
export const updateEmployeePersonalInfoSuccess = (result) => ({
  type: Types.UPDATE_EMPLOYEE_PERSONAL_INFO_SUCCESS,
  result,
});

export const getEmployeeJobInfoRequest = (params) => ({
  type: Types.GET_EMPLOYEE_JOB_INFO_REQUEST,
  params,
});
export const getEmployeeJobInfoSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_JOB_INFO_SUCCESS,
  result,
});

export const getEmployeeCompensationRequest = (filters) => ({
  type: Types.GET_EMPLOYEE_COMPENSATION_REQUEST,
  filters,
});

export const getEmployeeCompensationSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_COMPENSATION_SUCCESS,
  result,
});

export const createEmploymentStatusRequest = (data) => ({
  type: Types.CREATE_EMPLOYMENT_STATUS_REQUEST,
  data,
});
export const createEmploymentStatusSuccess = (result) => ({
  type: Types.CREATE_EMPLOYMENT_STATUS_SUCCESS,
  result,
});

export const getEmployeeEmploymentStatusRequest = (params) => ({
  type: Types.GET_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST,
  params,
});

export const getEmployeeEmploymentStatusSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS,
  result,
});

export const deleteEmployeeEmploymentStatusRequest = (data) => ({
  type: Types.DELETE_EMPLOYEE_EMPLOYMENT_STATUS_REQUEST,
  data,
});
export const deleteEmployeeEmploymentStatusSuccess = (result) => ({
  type: Types.DELETE_EMPLOYEE_EMPLOYMENT_STATUS_SUCCESS,
  result,
});

export const createEmployeeJobInfoRequest = (data) => ({
  type: Types.CREATE_EMPLOYEE_JOB_INFO_REQUEST,
  data,
});
export const createEmployeeJobInfoSuccess = (result) => ({
  type: Types.CREATE_EMPLOYEE_JOB_INFO_SUCCESS,
  result,
});
export const deleteEmployeeJobInfoRequest = (data) => ({
  type: Types.DELETE_EMPLOYEE_JOB_INFO_REQUEST,
  data,
});
export const deleteEmployeeJobInfoSuccess = (result) => ({
  type: Types.DELETE_EMPLOYEE_JOB_INFO_SUCCESS,
  result,
});

export const createEmployeeCompensationRequest = (payload) => ({
  type: Types.CREATE_EMPLOYEE_COMPENSATION_REQUEST,
  payload,
});
export const createEmployeeCompensationSuccess = (result) => ({
  type: Types.CREATE_EMPLOYEE_COMPENSATION_SUCCESS,
  result,
});
export const deleteEmployeeCompensationRequest = (payload) => ({
  type: Types.DELETE_EMPLOYEE_COMPENSATION_REQUEST,
  payload,
});
export const deleteEmployeeCompensationSuccess = (result) => ({
  type: Types.DELETE_EMPLOYEE_COMPENSATION_SUCCESS,
  result,
});

export const getEmployeeEmergencyContactsRequest = (data) => ({
  type: Types.GET_EMPLOYEE_EMERGENCY_CONTACTS_REQUEST,
  data,
});
export const getEmployeeEmergencyContactsSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_EMERGENCY_CONTACTS_SUCCESS,
  result,
});
export const createEmployeeEmergencyContactRequest = (data) => ({
  type: Types.CREATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST,
  data,
});
export const createEmployeeEmergencyContactSuccess = (result) => ({
  type: Types.CREATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  result,
});
export const updateEmployeeEmergencyContactRequest = (data) => ({
  type: Types.UPDATE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST,
  data,
});
export const updateEmployeeEmergencyContactSuccess = (result) => ({
  type: Types.UPDATE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  result,
});
export const deleteEmployeeEmergencyContactRequest = (data) => ({
  type: Types.DELETE_EMPLOYEE_EMERGENCY_CONTACT_REQUEST,
  data,
});
export const deleteEmployeeEmergencyContactSuccess = (result) => ({
  type: Types.DELETE_EMPLOYEE_EMERGENCY_CONTACT_SUCCESS,
  result,
});

export const getEmployeeBenefitsRequest = (params) => ({
  type: Types.GET_EMPLOYEE_BENEFITS_REQUEST,
  params,
});
export const getEmployeeBenefitsSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_BENEFITS_SUCCESS,
  result,
});

export const getEmployeeTimeOffsRequest = (filter) => ({
  type: Types.GET_EMPLOYEE_TIME_OFFS_REQUEST,
  filter,
});
export const getEmployeeTimeOffsSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_TIME_OFFS_SUCCESS,
  result,
});
export const updateEmployeeTimeOffRequest = (payload) => ({
  type: Types.UPDATE_EMPLOYEE_TIME_OFF_REQUEST,
  payload,
});
export const updateEmployeeTimeOffSuccess = (result) => ({
  type: Types.UPDATE_EMPLOYEE_TIME_OFF_SUCCESS,
  result,
});
export const deleteEmployeeTimeOffRequest = (params) => ({
  type: Types.DELETE_EMPLOYEE_TIME_OFF_REQUEST,
  params,
});
export const deleteEmployeetimeOffSuccess = (result) => ({
  type: Types.DELETE_EMPLOYEE_TIME_OFF_SUCCESS,
  result,
});

export const getEmployeePayStubsRequest = (filters) => ({
  type: Types.GET_EMPLOYEE_PAY_STUBS_REQUEST,
  filters,
});

export const getEmployeePayStubsSuccess = (result) => ({
  type: Types.GET_EMPLOYEE_PAY_STUBS_SUCCESS,
  result,
});

export const uploadAvatarEmployeeRequest = (payload) => ({
  type: Types.UPLOAD_AVATAR_REQUEST,
  payload,
});

export const uploadAvatarEmployeeSuccess = (result) => ({
  type: Types.UPLOAD_AVATAR_SUCCESS,
  result,
});

export const changeRoleEmployeeRequest = (payload) => ({
  type: Types.CHANGE_EMPLOYEE_ROLE_REQUEST,
  payload,
});

export const changeRoleEmployeeSuccess = (result) => ({
  type: Types.CHANGE_EMPLOYEE_ROLE_SUCCESS,
  result,
});

export const changePasswordEmployeeRequest = (payload) => ({
  type: Types.CHANGE_PASSWORD_REQUEST,
  payload,
});

export const changePasswordEmployeeSuccess = (result) => ({
  type: Types.CHANGE_PASSWORD_SUCCESS,
  result,
});

export const resetPasswordEmployeeRequest = (payload) => ({
  type: Types.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordEmployeeSuccess = (result) => ({
  type: Types.RESET_PASSWORD_SUCCESS,
  result,
});

export const changeStatusEmployeeRequest = (payload) => ({
  type: Types.CHANGE_STATUS_EMPLOYEE_REQUEST,
  payload,
});

export const changeStatusEmployeeSuccess = (result) => ({
  type: Types.CHANGE_STATUS_EMPLOYEE_SUCCESS,
  result,
});